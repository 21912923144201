import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import illustration from "../assets/login_orders_apps.svg";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Link,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import api from "../utils/api";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HomeIcon from "@mui/icons-material/Home";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgb(26, 35, 126)",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post("/login", { email, password });

      if (response.data.status === "success") {
        login(response.data.access_token, response.data.refresh_token);

        setSnackbar({
          open: true,
          message: t("auth.successful.login"),
          severity: "success",
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 200);
      } else {
        throw new Error(response.data.message || t("auth.error.login"));
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || error.message || t("auth.api.error"),
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Paper elevation={3} sx={{ mt: 8, p: 4, display: "flex" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                  <LockOutlinedIcon sx={{ mr: 1, color: "primary.main" }} />
                  <Typography component="h1" variant="h4">
                    {t("auth.login")}
                  </Typography>
                </Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ width: "100%" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("auth.email")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("auth.password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, py: 1.5 }}
                    disabled={isLoading}
                  >
                    {isLoading ? t("auth.logging_in") : t("auth.login")}
                  </Button>
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Link
                      component={RouterLink}
                      to="/signup"
                      variant="body2"
                    >
                      {t("auth.no_account")} {t("auth.register")}
                    </Link>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                      component={RouterLink}
                      to="/"
                      startIcon={<HomeIcon />}
                      variant="outlined"
                    >
                      {t("general.back_to_home")}
                    </Button>
                    <Link
                      component={RouterLink}
                      to="/forgot-password"
                      variant="body2"
                    >
                      {t("auth.forgot_password")}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="h5" gutterBottom>
                  {t("illustration.login_title")}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t("illustration.login_description")}
                </Typography>
                <Box
                  component="img"
                  src={illustration}
                  alt={t("illustration.login_alt")}
                  sx={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default SignIn;
