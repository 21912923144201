import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Delete, Add, Info } from "@mui/icons-material";
import themes from "../themes";

const allComponents = [
  { name: "Header", description: "Top section of the app" },
  { name: "PromotionalBanner", description: "Banner for promotions" },
  { name: "CategoryList", description: "List of product categories" },
  { name: "ProductList", description: "Display of products" },
  { name: "FooterNavigation", description: "Bottom navigation bar" },
];

function StylingOptionsSidebar({
  components,
  onRemoveComponent,
  onAddComponent,
  productCardSize,
  onProductCardSizeChange,
  selectedTheme,
  onThemeChange,
  onComponentHover,
  currentPage,
}) {
  const [hoveredComponent, setHoveredComponent] = useState(null);

  const handleInfoHover = useCallback(
    (component, isHovering) => {
      setHoveredComponent(isHovering ? component : null);
      onComponentHover(isHovering ? component : null);
    },
    [onComponentHover],
  );

  const themeOptions = useMemo(
    () =>
      Object.keys(themes).map((theme) => (
        <MenuItem key={theme} value={theme}>
          {theme.charAt(0).toUpperCase() + theme.slice(1)}
        </MenuItem>
      )),
    [],
  );

  const componentListItems = useMemo(
    () =>
      allComponents.map(({ name, description }) => (
        <ListItem
          key={name}
          secondaryAction={
            <>
              <Tooltip title={description}>
                <IconButton
                  edge="end"
                  aria-label="info"
                  onMouseEnter={() => handleInfoHover(name, true)}
                  onMouseLeave={() => handleInfoHover(name, false)}
                >
                  <Info />
                </IconButton>
              </Tooltip>
              {components.includes(name) ? (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRemoveComponent(name)}
                >
                  <Delete />
                </IconButton>
              ) : (
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => onAddComponent(name)}
                >
                  <Add />
                </IconButton>
              )}
            </>
          }
        >
          <ListItemText primary={name} />
        </ListItem>
      )),
    [components, handleInfoHover, onRemoveComponent, onAddComponent],
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Customization Options
      </Typography>
      <Divider />

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="subtitle1">Theme</Typography>
        <Select
          fullWidth
          value={selectedTheme}
          onChange={(e) => onThemeChange(e.target.value)}
          sx={{ marginBottom: 2 }}
        >
          {themeOptions}
        </Select>
      </Box>
      <Divider />

      {currentPage === 0 && (
        <>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">Components</Typography>
            <List>{componentListItems}</List>
          </Box>
          <Divider />

          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">Product Card Size</Typography>
            <Select
              fullWidth
              value={productCardSize}
              onChange={(e) => onProductCardSizeChange(e.target.value)}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="small">Small</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="large">Large</MenuItem>
            </Select>
          </Box>
        </>
      )}
    </Box>
  );
}

export default React.memo(StylingOptionsSidebar);
