import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import logo from "../assets/logo.png";
import { useTheme } from "@mui/system";

function Header() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "rgba(26, 35, 126, 0.95)",
        color: "#fff",
        padding: isMobile ? "8px 16px" : "8px 32px",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo and App Name */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logo}
            alt="Orders Apps Logo"
            style={{
              height: isMobile ? "18px" : "40px",
              marginRight: isMobile ? "4px" : "8px",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Language Selector */}
          <IconButton
            aria-label="language"
            onClick={handleLanguageMenuClick}
            color="inherit"
            sx={{
              padding: isMobile ? "4px" : "8px",
              fontSize: isMobile ? "18px" : "24px",
            }}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleLanguageChange("en")}>
              English
            </MenuItem>
            <MenuItem onClick={() => handleLanguageChange("ar")}>عربي</MenuItem>
          </Menu>

          {/* Authentication Buttons */}
          <Button
            color="inherit"
            component={Link}
            to="/signin"
            sx={{
              backgroundColor: "#1a237e",
              color: "#fff",
              borderRadius: "20px",
              textTransform: "none",
              fontWeight: "bold",
              padding: isMobile ? "4px 12px" : "6px 20px",
              fontSize: isMobile ? "12px" : "14px",
              marginLeft: isMobile ? "8px" : "16px",
              "&:hover": {
                backgroundColor: "#3c4fc7",
              },
            }}
          >
            {t("auth.login")}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/signup"
            sx={{
              background: "linear-gradient(45deg, #1a237e, #6a1b9a)",
              color: "#fff",
              borderRadius: "20px",
              textTransform: "none",
              fontWeight: "bold",
              padding: isMobile ? "4px 12px" : "6px 20px",
              fontSize: isMobile ? "12px" : "14px",
              marginLeft: isMobile ? "8px" : "8px",
              "&:hover": {
                background: "linear-gradient(45deg, #3c4fc7, #8e24aa)",
              },
            }}
          >
            {t("auth.register")}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
