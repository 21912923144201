import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { FeatureCard } from "./FeatureCard";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Storefront,
  Palette,
  Assessment,
  SupportAgent,
} from "@mui/icons-material";

const features = [
  {
    title: "control.title",
    description: "control.description",
    icon: BarChart,
  },
  {
    title: "marketing.title",
    description: "marketing.description",
    icon: Storefront,
  },
  {
    title: "customization.title",
    description: "customization.description",
    icon: Palette,
  },
  {
    title: "analysis.title",
    description: "analysis.description",
    icon: Assessment,
  },
  {
    title: "customer_service.title",
    description: "customer_service.description",
    icon: SupportAgent,
  },
];

function FeaturesSection() {
  const { t } = useTranslation();
  return (
    <Box component="section" sx={{ py: 8, backgroundColor: "#f4f6f8" }}>
      <Container>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{ 
            fontWeight: "bold", 
            marginBottom: "40px",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }
          }}
        >
          {t("features.section_title")}
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard
                feature={{
                  title: t(feature.title),
                  description: t(feature.description),
                  icon: feature.icon,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturesSection;
