import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Toolbar,
  Grid,
  Typography,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MetricCard from "./MetricCard";
import { useTranslation } from "react-i18next";
import api from "../utils/api";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const DashboardPanel = ({ config }) => {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchedRef = useRef(false);

  useEffect(() => {
    const getAnalytics = async () => {
      if (fetchedRef.current) return;
      fetchedRef.current = true;

      try {
        const data = await api.fetchAnalytics();
        setAnalytics(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getAnalytics();
  }, []);

  const CustomBarChart = ({ data, dataKey, xAxisKey, title }) => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={dataKey} fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );

  const CustomAreaChart = ({ data, dataKey, xAxisKey, title }) => (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.3}
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  const ConversionFunnel = () => {
    const stages = analytics?.conversion_funnel || [];
    const colors = ["#d4caed", "#ac9ed6", "#8884d8"];
    const [hoveredStage, setHoveredStage] = useState(null);

    return (
      <Box sx={{ width: "100%", height: 400, position: "relative" }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 400 400"
          preserveAspectRatio="xMidYMid meet"
        >
          {stages.slice(0, 3).map((stage, index) => {
            const topY = (index * 400) / 3;
            const bottomY = ((index + 1) * 400) / 3;
            const topWidth = 400 - (index * 400) / 3;
            const bottomWidth = 400 - ((index + 1) * 400) / 3;

            return (
              <g
                key={stage.name}
                onMouseEnter={() => setHoveredStage(index)}
                onMouseLeave={() => setHoveredStage(null)}
              >
                <path
                  d={`M ${200 - topWidth / 2} ${topY} 
                      L ${200 + topWidth / 2} ${topY} 
                      L ${200 + bottomWidth / 2} ${bottomY}
                      L ${200 - bottomWidth / 2} ${bottomY} Z`}
                  fill={colors[index]}
                  style={{ cursor: "pointer" }}
                />
                {hoveredStage === index && (
                  <>
                    <rect
                      x={200 - 100}
                      y={(topY + bottomY) / 2 - 40}
                      width={200}
                      height={80}
                      fill="rgba(255, 255, 255, 0.9)"
                      rx={5}
                      ry={5}
                    />
                    <text
                      x="200"
                      y={(topY + bottomY) / 2 - 10}
                      textAnchor="middle"
                      fill="#333"
                      fontSize="18"
                      fontWeight="bold"
                    >
                      {stage.name}
                    </text>
                    <text
                      x="200"
                      y={(topY + bottomY) / 2 + 20}
                      textAnchor="middle"
                      fill="#333"
                      fontSize="16"
                    >
                      {stage.value.toLocaleString()}
                    </text>
                  </>
                )}
              </g>
            );
          })}
        </svg>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          {t("dashboard.errorLoading")}: {error}
        </Typography>
      </Box>
    );
  }

  // Generate dates for the last 7 days
  const generateDates = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(date.toISOString().split("T")[0]);
    }
    return dates;
  };

  // Handle missing or empty data points and ensure all dates are present
  const sanitizeData = (data, key) => {
    const dates = generateDates();
    const dataMap = new Map(data.map((item) => [item.date, item[key] || 0]));

    return dates.map((date) => ({
      date: new Date(date).toLocaleDateString(),
      [key]: dataMap.get(date) || 0,
    }));
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />

      {/* Metric Cards Section */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          {t("dashboard.keyMetrics")}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.totalOrders"
              value={analytics.total_orders.toLocaleString()}
              icon={<ShoppingCartIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.totalSales"
              value={`${config.config.currency}${analytics.total_sales.toLocaleString()}`}
              icon={<BarChartIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.newCustomers"
              value={analytics.new_customers.toLocaleString()}
              icon={<PeopleIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.conversionRate"
              value={`${analytics.conversion_rate.toFixed(2)}%`}
              icon={<TrendingUpIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.newOrders"
              value={analytics.new_orders.toLocaleString()}
              icon={<LocalMallIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.lastWeekSales"
              value={`${config.config.currency}${analytics.last_week_sales.toLocaleString()}`}
              icon={<DateRangeIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.lastMonthSales"
              value={`${config.config.currency}${analytics.last_month_sales.toLocaleString()}`}
              icon={<DateRangeIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.lastWeekOrders"
              value={analytics.last_week_orders.toLocaleString()}
              icon={<ShoppingCartIcon color="secondary" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="dashboard.lastMonthOrders"
              value={analytics.last_month_orders.toLocaleString()}
              icon={<ShoppingCartIcon color="secondary" />}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Charts Section */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          {t("dashboard.charts")}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          {/* Daily Visits Chart */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("dashboard.lastWeekVisits")}
              </Typography>
              <CustomBarChart
                data={sanitizeData(analytics.daily_visits, "visits")}
                dataKey="visits"
                xAxisKey="date"
                title={t("dashboard.lastWeekVisits")}
              />
            </Paper>
          </Grid>

          {/* Daily Sales Chart */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("dashboard.lastWeekSalesChart")}
              </Typography>
              <CustomAreaChart
                data={sanitizeData(analytics.daily_sales, "sales")}
                dataKey="sales"
                xAxisKey="date"
                title={t("dashboard.lastWeekSalesChart")}
              />
            </Paper>
          </Grid>

          {/* Conversion Funnel */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("dashboard.conversionFunnel")}
              </Typography>
              <ConversionFunnel />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DashboardPanel;
