import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import {
  ShoppingBag,
  LocalGroceryStore,
  Category,
  Apple,
} from "@mui/icons-material";

const categories = [
  { icon: <ShoppingBag />, label: "Category1", id: 1 },
  { icon: <LocalGroceryStore />, label: "Category2", id: 2 },
  { icon: <Category />, label: "Category3", id: 3 },
  { icon: <Apple />, label: "Category4", id: 4 },
];

function CategoryList({ onCategoryClick }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        padding: 2,
        bgcolor: "#f8f8f8",
      }}
    >
      {categories.map((category) => (
        <Box key={category.id} sx={{ textAlign: "center" }}>
          <IconButton color="inherit" onClick={() => onCategoryClick(category)}>
            {category.icon}
          </IconButton>
          <Typography variant="caption">{category.label}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default CategoryList;
