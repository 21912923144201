import React from "react";
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function CustomizationPopup({
  open,
  onClose,
  text,
  subtitle,
  color,
  onTextChange,
  onSubtitleChange,
  onColorChange,
}) {
  const colors = [
    { name: "Yellow", value: "#f8e71c" },
    { name: "Teal", value: "#1abc9c" },
    { name: "Coral", value: "#e74c3c" },
    { name: "Gold", value: "#f39c12" },
    { name: "Navy", value: "#3498db" },
    { name: "Lavender", value: "#9b59b6" },
    { name: "Dark Blue", value: "#34495e" },
    { name: "Mint Green", value: "#2ecc71" },
    { name: "Orange", value: "#e67e22" },
    { name: "Light Grey", value: "#ecf0f1" },
    { name: "Charcoal", value: "#2c3e50" },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          Customize Banner
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <TextField
            fullWidth
            label="Banner Text"
            value={text}
            onChange={(e) => onTextChange(e.target.value)}
            variant="outlined"
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Subtitle Text"
            value={subtitle}
            onChange={(e) => onSubtitleChange(e.target.value)}
            variant="outlined"
            sx={{ mt: 2 }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel>Banner Color</InputLabel>
            <Select
              value={color}
              onChange={(e) => onColorChange(e.target.value)}
              label="Banner Color"
            >
              {colors.map((color) => (
                <MenuItem key={color.value} value={color.value}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        bgcolor: color.value,
                        borderRadius: "50%",
                        marginRight: 1,
                        border: "1px solid #ddd",
                      }}
                    />
                    {color.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CustomizationPopup;
