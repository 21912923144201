import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    FormControlLabel,
    Switch,
    Card,
    CardContent,
    Grid,
    Snackbar,
    Alert
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from "react-i18next";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import api from '../utils/api';

function StorePoliciesPanel({ config }) {
    const { t } = useTranslation();
    const [policies, setPolicies] = useState({
        base_info: {
            store_email: '',
            store_phone: '',
            company_address: '',
            activate_policies: true,
        },
        shipping_policy: { title: '', content: '' },
        refund_policy: { title: '', content: '' },
        cash_on_delivery_policy: { title: '', content: '', enabled: true },
    });
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        fetchPolicies();
    }, []);

    const fetchPolicies = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/policies');
            setPolicies(response.data);
        } catch (error) {
            console.error('Error fetching policies:', error);
            setSnackbar({
                open: true,
                message: t('storePolicies.errorFetching'),
                severity: 'error'
            });
        }
        setIsLoading(false);
    };

    const handleSavePolicies = async () => {
        setIsLoading(true);
        try {
            await api.post('/policies', {
                base_info: policies.base_info,
                shipping_policy: policies.shipping_policy,
                refund_policy: policies.refund_policy,
                cash_on_delivery_policy: policies.cash_on_delivery_policy.enabled ? policies.cash_on_delivery_policy : null,
            });
            setSnackbar({
                open: true,
                message: t('storePolicies.saveSuccess'),
                severity: 'success'
            });
        } catch (error) {
            console.error('Error saving policies:', error);
            setSnackbar({
                open: true,
                message: t('storePolicies.saveError'),
                severity: 'error'
            });
        }
        setIsLoading(false);
    };

    const handleInputChange = (section, field, value) => {
        setPolicies(prevPolicies => ({
            ...prevPolicies,
            [section]: {
                ...prevPolicies[section],
                [field]: value
            }
        }));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const renderTip = useCallback(
        (tipKey) => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 193, 7, 0.1)',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    border: '1px solid rgba(255, 193, 7, 0.5)',
                }}
            >
                <WarningAmberIcon sx={{ color: 'warning.main', marginRight: '12px' }} />
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    {t(tipKey)}
                </Typography>
            </Box>
        ),
        [t]
    );

    const quillModules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ['blockquote', 'code-block'],
            ['link', 'image'],
            ['clean']
        ],
    };

    const quillFormats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'align',
        'color',
        'background',
        'blockquote',
        'code-block',
        'link',
        'image'
    ];

    const quillStyles = {
        editor: {
            minHeight: '300px',
            background: '#ffffff',
        },
        toolbar: {
            background: '#f1f3f4',
            border: '1px solid #ccc',
            borderRadius: '4px 4px 0 0',
        },
        content: {
            border: '1px solid #ccc',
            borderTop: 'none',
            borderRadius: '0 0 4px 4px',
            color: '#333',
        },
    };

    return (
        <Box sx={{ overflowY: 'auto', p: 3, bgcolor: 'background.paper' }}>
            <Typography variant="h4" gutterBottom>
                {t('storePolicies.title')}
            </Typography>

            <FormControlLabel
                control={
                    <Switch
                        checked={policies.base_info.activate_policies}
                        onChange={(e) => handleInputChange('base_info', 'activate_policies', e.target.checked)}
                        color="primary"
                    />
                }
                label={t('storePolicies.activatePolicies')}
                sx={{ mb: 4 }}
            />

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>{t('storePolicies.baseInformation')}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {renderTip('storePolicies.tips.storeEmail')}
                            <TextField
                                fullWidth
                                name="storeEmail"
                                label={t('storePolicies.storeEmail')}
                                value={policies.base_info.store_email}
                                onChange={(e) => handleInputChange('base_info', 'store_email', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderTip('storePolicies.tips.storePhone')}
                            <TextField
                                fullWidth
                                name="storePhone"
                                label={t('storePolicies.storePhone')}
                                value={policies.base_info.store_phone}
                                onChange={(e) => handleInputChange('base_info', 'store_phone', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderTip('storePolicies.tips.companyAddress')}
                            <TextField
                                fullWidth
                                name="companyAddress"
                                label={t('storePolicies.companyAddress')}
                                multiline
                                rows={3}
                                value={policies.base_info.company_address}
                                onChange={(e) => handleInputChange('base_info', 'company_address', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card elevation={0} sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>{t('storePolicies.shippingPolicy')}</Typography>
                    {renderTip('storePolicies.tips.shippingPolicy')}
                    <TextField
                        fullWidth
                        name="shippingPolicyTitle"
                        label={t('storePolicies.policyTitle')}
                        value={policies.shipping_policy.title}
                        onChange={(e) => handleInputChange('shipping_policy', 'title', e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{
                        '.ql-toolbar': quillStyles.toolbar,
                        '.ql-container': quillStyles.content,
                        '.ql-editor': quillStyles.editor,
                    }}>
                        <ReactQuill
                            value={policies.shipping_policy.content}
                            onChange={(content) => handleInputChange('shipping_policy', 'content', content)}
                            modules={quillModules}
                            formats={quillFormats}
                            theme="snow"
                        />
                    </Box>
                </CardContent>
            </Card>

            <Card elevation={0} sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>{t('storePolicies.refundPolicy')}</Typography>
                    {renderTip('storePolicies.tips.refundPolicy')}
                    <TextField
                        fullWidth
                        name="refundPolicyTitle"
                        label={t('storePolicies.policyTitle')}
                        value={policies.refund_policy.title}
                        onChange={(e) => handleInputChange('refund_policy', 'title', e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{
                        '.ql-toolbar': quillStyles.toolbar,
                        '.ql-container': quillStyles.content,
                        '.ql-editor': quillStyles.editor,
                    }}>
                        <ReactQuill
                            value={policies.refund_policy.content}
                            onChange={(content) => handleInputChange('refund_policy', 'content', content)}
                            modules={quillModules}
                            formats={quillFormats}
                            theme="snow"
                        />
                    </Box>
                </CardContent>
            </Card>

            <Card elevation={0} sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>{t('storePolicies.cashOnDeliveryPolicy')}</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={policies.cash_on_delivery_policy.enabled}
                                onChange={(e) => handleInputChange('cash_on_delivery_policy', 'enabled', e.target.checked)}
                                color="primary"
                            />
                        }
                        label={t('storePolicies.enableCashOnDelivery')}
                        sx={{ mb: 2 }}
                    />
                    {policies.cash_on_delivery_policy.enabled && (
                        <>
                            {renderTip('storePolicies.tips.cashOnDeliveryPolicy')}
                            <TextField
                                fullWidth
                                name="cashOnDeliveryPolicyTitle"
                                label={t('storePolicies.policyTitle')}
                                value={policies.cash_on_delivery_policy.title}
                                onChange={(e) => handleInputChange('cash_on_delivery_policy', 'title', e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <Box sx={{
                                '.ql-toolbar': quillStyles.toolbar,
                                '.ql-container': quillStyles.content,
                                '.ql-editor': quillStyles.editor,
                            }}>
                                <ReactQuill
                                    value={policies.cash_on_delivery_policy.content}
                                    onChange={(content) => handleInputChange('cash_on_delivery_policy', 'content', content)}
                                    modules={quillModules}
                                    formats={quillFormats}
                                    theme="snow"
                                />
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSavePolicies}
                    disabled={isLoading}
                >
                    {t('common.save')}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default StorePoliciesPanel;
