import React, { useState, useCallback, useMemo } from "react";
import { Box, Typography, Grid, Button, IconButton } from "@mui/material";
import { Add, Remove, ShoppingCart } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../slices/cartSlice";

const initialProducts = [
  {
    id: 1,
    title: "Product 1",
    price: "$10.00",
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
    description: "This is the description for product 1.",
  },
  {
    id: 2,
    title: "Product 2",
    price: "$15.00",
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
    description: "This is the description for product 2.",
  },
  {
    id: 3,
    title: "Product 3",
    price: "$20.00",
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
    description: "This is the description for product 3.",
  },
  // Add more products if needed to ensure scrolling
];

function ProductList({ productCardSize, styles, onAddToCart, onProductClick }) {
  const dispatch = useDispatch();

  const [products, setProducts] = useState(() =>
    initialProducts.map((product) => ({ ...product, quantity: 1 })),
  );

  const handleQuantityChange = useCallback((id, delta) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, quantity: Math.max(1, product.quantity + delta) }
          : product,
      ),
    );
  }, []);

  const handleAddToCart = useCallback(
    (id) => {
      const product = products.find((product) => product.id === id);
      dispatch(addItemToCart({ ...product }));
      onAddToCart(product, product.quantity); // Call the onAddToCart function passed from the Template component
    },
    [dispatch, onAddToCart, products],
  );

  const getGridSize = useCallback((size) => {
    switch (size) {
      case "small":
        return 4; // 3 cards per row
      case "medium":
        return 6; // 2 cards per row
      case "large":
        return 12; // 1 card per row
      default:
        return 6;
    }
  }, []);

  const gridSize = useMemo(
    () => getGridSize(productCardSize),
    [productCardSize, getGridSize],
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        padding: 2,
        bgcolor: styles?.bgColor || "#f8f8f8",
        height: "100%",
      }}
    >
      <Typography
        variant="h5"
        sx={{ mb: 2, color: styles?.textColor || "#000" }}
      >
        Products
      </Typography>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={gridSize} key={product.id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: styles?.cardBgColor || "#fff",
                borderRadius: 2,
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                height: "100%",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={() => onProductClick(product)}
            >
              <img
                src={product.images[0]} // Display the first image as a thumbnail
                alt={product.title}
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: styles?.textColor || "#000", mb: 1 }}
                >
                  {product.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: styles?.priceColor || styles?.textColor || "#000",
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {product.price}
                </Typography>
                {productCardSize !== "small" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                      gap: 1,
                    }}
                  >
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the product click event
                        handleQuantityChange(product.id, -1);
                      }}
                    >
                      <Remove fontSize="small" />
                    </IconButton>
                    <Typography variant="body1">{product.quantity}</Typography>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the product click event
                        handleQuantityChange(product.id, 1);
                      }}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        padding: 0,
                        fontSize: "0.875rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "black", // Set button color to black
                        color: "white", // Set text color to white
                        "&:hover": {
                          bgcolor: "black", // Ensure the hover color is also black
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the product click event
                        handleAddToCart(product.id);
                      }}
                    >
                      <ShoppingCart fontSize="small" />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(ProductList);
