import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { clearBuyNow } from "../../slices/cartSlice";

// Import images
import MyFatoorahLogo from "../../assets/myfatoorah.png";
import TapLogo from "../../assets/tap.png";
import StripeLogo from "../../assets/stripe.png";
import PaypalLogo from "../../assets/paypal.png";
import PayLinkLogo from "../../assets/paylink.png";

function Checkout() {
  const [expanded, setExpanded] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("cod");
  const cartItems = useSelector((state) => state.cart.items);
  const buyNowItems = useSelector((state) => state.cart.buyNowItems);
  const dispatch = useDispatch();
  const items = buyNowItems.length > 0 ? buyNowItems : cartItems;

  const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = items.reduce(
    (total, item) =>
      total + parseFloat(item.price.replace(/[^0-9.-]+/g, "")) * item.quantity,
    0,
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const parsePrice = (price) => parseFloat(price.replace(/[^0-9.-]+/g, ""));

  const handleConfirmOrder = () => {
    // Add your order confirmation logic here
    dispatch(clearBuyNow());
    console.log("Order confirmed");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        bgcolor: "#fff",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Personal Details
      </Typography>
      <TextField fullWidth label="Full Name" sx={{ mb: 2 }} />
      <TextField fullWidth label="Email Address" sx={{ mb: 2 }} />
      <TextField fullWidth label="Phone Number" sx={{ mb: 2 }} />
      <TextField
        fullWidth
        label="Shipping Address"
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Payment Method
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="payment method"
          name="payment method"
          value={paymentMethod}
          onChange={handlePaymentChange}
        >
          <FormControlLabel
            value="cod"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Cash on delivery</Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="myfatoorah"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={MyFatoorahLogo}
                  alt="My Fatoorah"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <Typography>My Fatoorah</Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="tap"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={TapLogo}
                  alt="Tap"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <Typography>Tap</Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="stripe"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={StripeLogo}
                  alt="Stripe"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <Typography>Stripe</Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="paypal"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={PaypalLogo}
                  alt="Paypal"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <Typography>Paypal</Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="paylink"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={PayLinkLogo}
                  alt="PayLink"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <Typography>PayLink</Typography>
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>

      <Accordion expanded={expanded} onChange={handleExpandClick}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Cart Details ({totalQuantity} items)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {items.map((item) => (
            <Box key={item.id} sx={{ display: "flex", mb: 1 }}>
              <img
                src={item.images[0]}
                alt={item.title}
                style={{ width: "60px", height: "auto", marginRight: "8px" }}
              />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1">{item.title}</Typography>
                <Typography variant="body2">
                  Quantity: {item.quantity}
                </Typography>
                <Typography variant="body2">
                  Price: ${(parsePrice(item.price) * item.quantity).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Typography variant="h6">Total</Typography>
        <Typography variant="h6">${totalPrice.toFixed(2)}</Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          bgcolor: "black",
          color: "white",
          "&:hover": { bgcolor: "black" },
        }}
        fullWidth
        onClick={handleConfirmOrder}
      >
        Confirm Order
      </Button>
    </Box>
  );
}

export default Checkout;
