import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

function Profile() {
  const [userDetails, setUserDetails] = useState({
    fullName: "John Doe",
    email: "john.doe@example.com",
    phone: "+1234567890",
    address: "123 Main St, City, Country",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    // Update user details logic
    console.log("User details updated:", userDetails);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        bgcolor: "#fff",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile
      </Typography>
      <TextField
        fullWidth
        label="Full Name"
        name="fullName"
        value={userDetails.fullName}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Email Address"
        name="email"
        value={userDetails.email}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Phone Number"
        name="phone"
        value={userDetails.phone}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Shipping Address"
        name="address"
        multiline
        rows={3}
        value={userDetails.address}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{
          bgcolor: "black",
          color: "white",
          "&:hover": { bgcolor: "black" },
        }}
        onClick={handleUpdate}
        fullWidth
      >
        Update Details
      </Button>
    </Box>
  );
}

export default Profile;
