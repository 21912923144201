import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const QASection = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const qaData = [
    {
      question: "qa.platform_distinction.question",
      answer: "qa.platform_distinction.answer",
    },
    {
      question: "qa.payment_methods.question",
      answer: "qa.payment_methods.answer",
    },
    {
      question: "qa.operation_locations.question",
      answer: "qa.operation_locations.answer",
    },
    {
      question: "qa.supported_languages.question",
      answer: "qa.supported_languages.answer",
    },
    {
      question: "qa.subscription_model.question",
      answer: "qa.subscription_model.answer",
    },
    {
      question: "qa.expired_subscription.question",
      answer: "qa.expired_subscription.answer",
    },
    {
      question: "qa.domain_services.question",
      answer: "qa.domain_services.answer",
    },
    {
      question: "qa.mobile_app_development.question",
      answer: "qa.mobile_app_development.answer",
    },
  ];

  return (
    <Box component="section" sx={{ bgcolor: "background.paper", py: 8 }}>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            marginBottom: "40px"
          }}
        >
          {t("qa.section_title")}
        </Typography>
        {qaData.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              '&:not(:last-child)': {
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
              },
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, .03)',
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': {
                  marginLeft: 2,
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {t(item.question)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t(item.answer)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default QASection;
