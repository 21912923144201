import React from "react";
import { Snackbar, Alert } from "@mui/material";

function Notification({ open, message, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{
        position: "absolute",
        width: "calc(100% - 20px)", // Ensure the Snackbar fits within the mobile screen
        left: "10px",
      }}
    >
      <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
