import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  TableSortLabel,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import OrderDetailsPanel from "./OrderDetailsPanel";
import { useTranslation } from "react-i18next";
import api from "../utils/api";

const InProgressOrdersPanel = ({ config }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get(
        `/orders/domain?page=${page + 1
        }&per_page=${rowsPerPage}&sort=${orderBy}&order=${order}&status=in_progress`
      );
      setOrders(response.data.orders);
      setTotalOrders(response.data.total);
    } catch (error) {
      setError(t("errors.failedToFetchOrders"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [page, rowsPerPage, orderBy, order, t]); // Kept `t` for potential language updates

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  const handleOrderClick = (orderId) => {
    setSelectedOrder(orderId);
  };

  const handleCloseOrderDetails = () => {
    setSelectedOrder(null);
  };

  if (selectedOrder) {
    return (
      <OrderDetailsPanel
        orderId={selectedOrder}
        onClose={handleCloseOrderDetails}
        config={config}
      />
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t("orders.inProgressOrders")}
      </Typography>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("orders.customer")}</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                  >
                    {t("orders.date")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "total"}
                    direction={orderBy === "total" ? order : "asc"}
                    onClick={() => handleRequestSort("total")}
                  >
                    {t("orders.total")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("orders.status")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {t("common.loading")}
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((order) => (
                  <TableRow
                    key={order.id}
                    onClick={() => handleOrderClick(order.id)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "action.hover" },
                    }}
                  >
                    <TableCell>{order.user_data.fullName}</TableCell>
                    <TableCell>
                      {new Date(order.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{config.config.currency}{Number(order.total).toFixed(2)}</TableCell>
                    <TableCell>
                      <Chip
                        label={t("orders.statuses.in_progress")}
                        color="warning"
                      />
                    </TableCell>
                    <TableCell>
                      {!order.seen && (
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                        >
                          {t("orders.new")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalOrders}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            t("pagination.displayedRows", {
              from,
              to,
              count: count !== -1 ? count : `more than ${to}`,
            })
          }
          getItemAriaLabel={(type) =>
            type === "first"
              ? t("pagination.firstPage")
              : type === "last"
                ? t("pagination.lastPage")
                : type === "next"
                  ? t("pagination.nextPage")
                  : t("pagination.previousPage")
          }
        />
      </Paper>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InProgressOrdersPanel;
