import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LanguageIcon from "@mui/icons-material/Language";
import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
import PolicyIcon from "@mui/icons-material/Policy";
import StarIcon from "@mui/icons-material/Star";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SendIcon from "@mui/icons-material/Send";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExtensionIcon from "@mui/icons-material/Extension";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CancelIcon from "@mui/icons-material/Cancel";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { alpha } from "@mui/material/styles";
import { useAuth } from "../contexts/AuthContext";
import DashboardPanel from "../components/DashboardPanel";
import CategoriesPanel from "../components/CategoriesPanel";
import ReviewsPanel from "../components/ReviewsPanel";
import AllProductsPanel from "../components/AllProductsPanel";
import AllOrdersPanel from "../components/AllOrdersPanel";
import PendingOrdersPanel from "../components/PendingOrdersPanel";
import DeliveredOrdersPanel from "../components/DeliveredOrdersPanel";
import CancelledOrdersPanel from "../components/CancelledOrdersPanel";
import NewOrdersPanel from "../components/NewOrdersPanel";
import AllCustomersPanel from "../components/AllCustomersPanel";
import NewCustomersPanel from "../components/NewCustomersPanel";
import InProgressOrdersPanel from "../components/InProgressOrdersPanel";
import ShippedOrdersPanel from "../components/ShippedOrdersPanel";
import StorePoliciesPanel from "../components/StorePoliciesPanel";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import api from "../utils/api";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create ltr cache
const cacheLtr = createCache({
  key: "muiltr",
});

const getTheme = (direction) =>
  createTheme({
    direction,
    palette: {
      mode: "dark",
      background: {
        default: "#1a1b26",
        paper: "#24283b",
      },
      primary: {
        main: "#7aa2f7",
      },
      secondary: {
        main: "#bb9af7",
      },
    },
    typography: {
      fontFamily: "'Inter', sans-serif",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });

const sidebarItems = [
  { id: "dashboard", title: "dashboard.title", icon: <DashboardIcon /> },
  {
    id: "products",
    title: "products.title",
    icon: <InventoryIcon />,
    subItems: [
      {
        id: "categories",
        title: "products.categories",
        icon: <CategoryIcon />,
      },
      { id: "reviews", title: "products.reviews", icon: <StarIcon /> },
      {
        id: "allProducts",
        title: "products.allProducts",
        icon: <ShoppingCartIcon />,
      },
    ],
  },
  {
    id: "orders",
    title: "orders.title",
    icon: <ListAltIcon />,
    subItems: [
      {
        id: "newOrders",
        title: "orders.newOrders",
        icon: <FiberNewIcon />,
      },
      {
        id: "allOrders",
        title: "orders.allOrders",
        icon: <ShoppingCartIcon />,
      },
      {
        id: "pendingOrders",
        title: "orders.pendingOrders",
        icon: <BarChartIcon />,
      },
      {
        id: "inProgressOrders",
        title: "orders.inProgressOrders",
        icon: <HourglassEmptyIcon />,
      },
      {
        id: "shippedOrders",
        title: "orders.shippedOrders",
        icon: <SendIcon />,
      },
      {
        id: "deliveredOrders",
        title: "orders.deliveredOrders",
        icon: <LocalShippingIcon />,
      },
      {
        id: "cancelledOrders",
        title: "orders.cancelledOrders",
        icon: <CancelIcon />,
      },
    ],
  },
  {
    id: "customers",
    title: "customers.title",
    icon: <PeopleIcon />,
    subItems: [
      {
        id: "allCustomers",
        title: "customers.allCustomers",
        icon: <GroupAddIcon />,
      },
      {
        id: "newCustomers",
        title: "customers.newCustomers",
        icon: <PersonIcon />,
      },
    ],
  },
  { id: "storePolicies", title: "storePolicies.title", icon: <PolicyIcon /> },
  { id: "playground", title: "nav.playground", icon: <ExtensionIcon /> },
];

function Dashboard() {
  const { isAuthenticated, isLoading, logout, user } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [direction, setDirection] = useState(
    i18n.language === "ar" ? "rtl" : "ltr"
  );
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [openItems, setOpenItems] = useState({});
  const [currentSection, setCurrentSection] = useState("dashboard");
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [config, setConfig] = useState(null);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/signin");
    }
  }, [isAuthenticated, isLoading, navigate]);

  useEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await api.get("/config");
        setConfig(response.data);
        setIsConfigLoading(false);
      } catch (error) {
        setIsConfigLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setDirection(lang === "ar" ? "rtl" : "ltr");
    setLanguageMenuAnchor(null);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const theme = useMemo(() => getTheme(direction), [direction]);

  const drawerWidth = sidebarOpen ? 240 : 60;

  const handleSidebarItemClick = (id) => {
    if (id === "playground") {
      navigate("/playground");
    } else if (sidebarItems.find((item) => item.id === id && item.subItems)) {
      setOpenItems((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    } else {
      setCurrentSection(id);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenu = (event) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchor(null);
  };

  const renderContent = () => {
    if (isConfigLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      );
    }

    switch (currentSection) {
      case "dashboard":
        return <DashboardPanel config={config} />;
      case "categories":
        return <CategoriesPanel />;
      case "allProducts":
        return <AllProductsPanel config={config} />;
      case "reviews":
        return <ReviewsPanel />;
      case "allOrders":
        return <AllOrdersPanel config={config} />;
      case "pendingOrders":
        return <PendingOrdersPanel config={config} />;
      case "shippedOrders":
        return <ShippedOrdersPanel config={config} />;
      case "inProgressOrders":
        return <InProgressOrdersPanel config={config} />;
      case "deliveredOrders":
        return <DeliveredOrdersPanel config={config} />;
      case "cancelledOrders":
        return <CancelledOrdersPanel config={config} />;
      case "newOrders":
        return <NewOrdersPanel config={config} />;
      case "allCustomers":
        return <AllCustomersPanel config={config} />;
      case "newCustomers":
        return <NewCustomersPanel config={config} />;
      case "storePolicies":
        return <StorePoliciesPanel config={config} />;
      default:
        return <DashboardPanel config={config} />;
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={direction === "rtl" ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ display: "flex", height: "100vh" }}>
            <AppBar
              position="fixed"
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: "background.paper",
                boxShadow: "none",
                borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
              }}
            >
              <Toolbar
                variant="dense"
                sx={{
                  justifyContent: "space-between",
                  paddingLeft: direction === "ltr" ? `${drawerWidth}px` : 0,
                  paddingRight: direction === "rtl" ? `${drawerWidth}px` : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    justifyContent:
                      direction === "rtl" ? "flex-end" : "flex-start",
                  }}
                >
                  <IconButton
                    edge={direction === "rtl" ? "end" : "start"}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleSidebar}
                    sx={{
                      mr: direction === "rtl" ? 0 : 2,
                      ml: direction === "rtl" ? 2 : 0,
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    sx={{ fontWeight: 600, flexGrow: 1 }}
                  >
                    {t("dashboard.title")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    size="large"
                    aria-label="show new notifications"
                    color="inherit"
                  >
                    <NotificationsIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={handleLanguageMenu}
                    sx={{ mx: 1 }}
                  >
                    <LanguageIcon />
                  </IconButton>
                  <Menu
                    id="language-menu"
                    anchorEl={languageMenuAnchor}
                    open={Boolean(languageMenuAnchor)}
                    onClose={handleLanguageMenuClose}
                  >
                    <MenuItem onClick={() => changeLanguage("en")}>
                      English
                    </MenuItem>
                    <MenuItem onClick={() => changeLanguage("ar")}>
                      العربية
                    </MenuItem>
                  </Menu>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Avatar
                      alt={user?.name || "User"}
                      src={user?.avatar}
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: direction === "rtl" ? "left" : "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: direction === "rtl" ? "left" : "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>{t("profile")}</MenuItem>
                    <MenuItem onClick={handleClose}>{config && config.domain}</MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>{t("logout")}</MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  transition: "width 0.2s",
                  border: "none",
                },
              }}
            >
              <Toolbar />
              <Box sx={{ overflow: "auto" }}>
                <List>
                  {sidebarItems.map((item) => (
                    <React.Fragment key={item.id}>
                      <ListItem
                        button
                        onClick={() => handleSidebarItemClick(item.id)}
                        sx={{
                          justifyContent: sidebarOpen ? "initial" : "center",
                          px: 2.5,
                          py: 1.5,
                          bgcolor:
                            currentSection === item.id
                              ? alpha(theme.palette.primary.main, 0.1)
                              : "transparent",
                          "&:hover": {
                            bgcolor: alpha(theme.palette.primary.main, 0.05),
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: sidebarOpen ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              currentSection === item.id
                                ? "primary.main"
                                : "inherit",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        {sidebarOpen && (
                          <ListItemText
                            primary={t(item.title)}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight:
                                currentSection === item.id ? 600 : "normal",
                              color:
                                currentSection === item.id
                                  ? "primary.main"
                                  : "inherit",
                            }}
                          />
                        )}
                        {item.subItems &&
                          sidebarOpen &&
                          (openItems[item.id] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                      </ListItem>
                      {item.subItems && (
                        <Collapse
                          in={openItems[item.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {item.subItems.map((subItem) => (
                              <ListItem
                                button
                                key={subItem.id}
                                onClick={() => setCurrentSection(subItem.id)}
                                sx={{
                                  pl: 4,
                                  py: 1,
                                  bgcolor:
                                    currentSection === subItem.id
                                      ? alpha(theme.palette.primary.main, 0.1)
                                      : "transparent",
                                  "&:hover": {
                                    bgcolor: alpha(
                                      theme.palette.primary.main,
                                      0.05
                                    ),
                                  },
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    color:
                                      currentSection === subItem.id
                                        ? "primary.main"
                                        : "inherit",
                                  }}
                                >
                                  {subItem.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={t(subItem.title)}
                                  primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight:
                                      currentSection === subItem.id
                                        ? 600
                                        : "normal",
                                    color:
                                      currentSection === subItem.id
                                        ? "primary.main"
                                        : "inherit",
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </Drawer>
            <Box
              component="main"
              sx={{
                p: 3,
                width: `calc(100% - ${drawerWidth}px)`,
                height: "100vh",
                overflow: "auto",
                flexGrow: 1,
                bgcolor: "background.default",
              }}
            >
              <Toolbar />
              {renderContent()}
            </Box>
          </Box>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
}

export default Dashboard;
