import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import Header from "./Header";
import CategoryList from "./CategoryList";
import PromotionalBanner from "./PromotionalBanner";
import FooterNavigation from "./FooterNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import CategoryDetail from "./CategoryDetail";
import Notification from "./Notification";
import Orders from "./Orders";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Profile from "./Profile";
import FilteringPage from "./FilteringPage";
import CustomizationPopup from "./CustomizationPopup";

const componentMap = {
  Header,
  PromotionalBanner,
  CategoryList,
  ProductList,
  FooterNavigation,
};

function Template({
  styles,
  components = [],
  productCardSize,
  hoveredComponent,
  highlightStyle,
  onNavigate,
}) {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [bannerText, setBannerText] = useState(
    "Unlimited $0 delivery fee banner",
  );
  const [bannerSubtitle, setBannerSubtitle] = useState("Order, save, and more");
  const [bannerColor, setBannerColor] = useState("#f8e71c");
  const [popupOpen, setPopupOpen] = useState(false);

  const handleAddToCart = (product, quantity) => {
    setNotification({
      open: true,
      message: `Added ${quantity} of ${product.title} to the cart`,
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleBackToProducts = () => {
    setSelectedProduct(null);
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
  };

  const handleNavigate = (page) => {
    setCurrentPage(page);
    onNavigate(page);
    setSelectedProduct(null);
    setSelectedCategory(null);
  };

  const handleBannerClick = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleTextChange = (newText) => {
    setBannerText(newText);
  };

  const handleSubtitleChange = (newSubtitle) => {
    setBannerSubtitle(newSubtitle);
  };

  const handleColorChange = (newColor) => {
    setBannerColor(newColor);
  };

  const handleApplyFilters = (filters) => {
    console.log("Applied filters:", filters);
    // Apply the filters to the product list
  };

  const filteredComponents = components.filter(
    (componentName) =>
      componentName !== "Header" && componentName !== "FooterNavigation",
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: styles?.bgColor || "#f8f8f8",
        position: "relative",
      }}
    >
      {components.includes("Header") && (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            bgcolor: styles?.bgColor || "#f8f8f8",
            ...(hoveredComponent === "Header" && highlightStyle),
            transition: "all 0.3s ease",
          }}
        >
          <Header styles={styles} onNavigate={handleNavigate} />
        </Box>
      )}
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        {selectedProduct ? (
          <ProductDetail
            product={selectedProduct}
            onAddToCart={handleAddToCart}
            onBack={handleBackToProducts}
            onBuyNow={() => handleNavigate(3)}
          />
        ) : selectedCategory ? (
          <CategoryDetail
            category={selectedCategory}
            onProductClick={handleProductClick}
            onAddToCart={handleAddToCart}
            onBack={handleBackToCategories}
          />
        ) : currentPage === 1 ? (
          <Orders />
        ) : currentPage === 2 ? (
          <Cart
            onProductClick={handleProductClick}
            onCheckout={() => handleNavigate(3)}
            onNavigate={handleNavigate}
          />
        ) : currentPage === 3 ? (
          <Checkout />
        ) : currentPage === 4 ? (
          <Profile />
        ) : currentPage === 5 ? (
          <FilteringPage
            onApplyFilters={handleApplyFilters}
            onBack={() => handleNavigate(0)}
          />
        ) : (
          filteredComponents.map((componentName, index) => {
            const Component = componentMap[componentName];
            const isHovered = hoveredComponent === componentName;
            return (
              <Box
                key={index}
                sx={{
                  flex: componentName === "ProductList" ? 1 : "none",
                  ...(isHovered && highlightStyle),
                  transition: "all 0.3s ease",
                }}
              >
                {componentName === "PromotionalBanner" ? (
                  <Component
                    text={bannerText}
                    subtitle={bannerSubtitle}
                    color={bannerColor}
                    onClick={handleBannerClick}
                  />
                ) : (
                  <Component
                    productCardSize={productCardSize}
                    styles={styles}
                    onAddToCart={handleAddToCart}
                    onProductClick={handleProductClick}
                    onCategoryClick={handleCategoryClick}
                  />
                )}
              </Box>
            );
          })
        )}
      </Paper>
      <Box sx={{ height: "56px" }}></Box>
      {components.includes("FooterNavigation") && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            zIndex: 1,
            bgcolor: styles?.bgColor || "#f8f8f8",
            ...(hoveredComponent === "FooterNavigation" && highlightStyle),
            transition: "all 0.3s ease",
          }}
        >
          <FooterNavigation onNavigate={handleNavigate} styles={styles} />
        </Box>
      )}
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={handleCloseNotification}
      />
      <CustomizationPopup
        open={popupOpen}
        onClose={handlePopupClose}
        text={bannerText}
        subtitle={bannerSubtitle}
        color={bannerColor}
        onTextChange={handleTextChange}
        onSubtitleChange={handleSubtitleChange}
        onColorChange={handleColorChange}
      />
    </Box>
  );
}

export default Template;
