import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getEstimatedDeliveryDate = (daysToAdd) => {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);
  return date.toISOString().split("T")[0];
};

const orders = [
  {
    id: 1,
    status: "In Progress",
    details: [
      { product: "Product A", quantity: 2, price: 10 },
      { product: "Product B", quantity: 1, price: 15 },
    ],
    estimatedDelivery: getEstimatedDeliveryDate(5),
  },
  {
    id: 2,
    status: "Delivered",
    details: [
      { product: "Product C", quantity: 1, price: 20 },
      { product: "Product D", quantity: 3, price: 25 },
    ],
  },
  {
    id: 3,
    status: "In Progress",
    details: [{ product: "Product E", quantity: 1, price: 30 }],
    estimatedDelivery: getEstimatedDeliveryDate(7),
  },
  {
    id: 4,
    status: "Cancelled",
    details: [
      { product: "Product F", quantity: 2, price: 35 },
      { product: "Product G", quantity: 1, price: 40 },
    ],
  },
  {
    id: 5,
    status: "Delivered",
    details: [{ product: "Product H", quantity: 1, price: 50 }],
  },
];

const groupedOrders = orders.reduce((groups, order) => {
  if (!groups[order.status]) {
    groups[order.status] = [];
  }
  groups[order.status].push(order);
  return groups;
}, {});

const statusTabs = Object.keys(groupedOrders);

function Orders() {
  const [currentTab, setCurrentTab] = useState(statusTabs[0]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        bgcolor: "#fff",
        borderRadius: 2,
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: "center", mb: 3, fontWeight: "bold" }}
      >
        Orders
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        sx={{ marginBottom: 2 }}
      >
        {statusTabs.map((status) => (
          <Tab key={status} label={status} value={status} />
        ))}
      </Tabs>
      {groupedOrders[currentTab].map((order) => (
        <Accordion key={order.id} sx={{ mb: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", flexGrow: 1 }}
              >
                Order #{order.id}
              </Typography>
              {order.status === "In Progress" && (
                <Typography
                  variant="body2"
                  sx={{ color: "gray", flexShrink: 0 }}
                >
                  Estimated Delivery Date: {order.estimatedDelivery}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.details.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography noWrap>{item.product}</Typography>
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>${item.price.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle1" fontWeight="bold">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight="bold">
                      $
                      {order.details
                        .reduce(
                          (total, item) => total + item.price * item.quantity,
                          0,
                        )
                        .toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default Orders;
