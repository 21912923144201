import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../utils/api";

const AllCustomersPanel = ({ config }) => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("lastOrderDate");
  const [order, setOrder] = useState("desc");
  const [totalCustomers, setTotalCustomers] = useState(0);
  const fetchRef = useRef(false);

  const fetchCustomers = useCallback(async () => {
    if (fetchRef.current) {
      fetchRef.current = false;
      return;
    }
    fetchRef.current = true;
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/customers", {
        params: {
          page: page + 1,
          per_page: rowsPerPage,
          sort: orderBy,
          order: order,
        },
      });
      setCustomers(response.data.customers);
      setTotalCustomers(response.data.total);
    } catch (err) {
      setError(t("errors.failedToFetchCustomers"));
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, orderBy, order, t]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t("customers.allCustomers")}
      </Typography>
      <Paper>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "fullName"}
                        direction={orderBy === "fullName" ? order : "asc"}
                        onClick={() => handleRequestSort("fullName")}
                      >
                        {t("customers.name")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "phoneNumber"}
                        direction={orderBy === "phoneNumber" ? order : "asc"}
                        onClick={() => handleRequestSort("phoneNumber")}
                      >
                        {t("customers.phoneNumber")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{t("customers.shippingAddress")}</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "totalOrders"}
                        direction={orderBy === "totalOrders" ? order : "asc"}
                        onClick={() => handleRequestSort("totalOrders")}
                      >
                        {t("customers.totalOrders")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "totalSpent"}
                        direction={orderBy === "totalSpent" ? order : "asc"}
                        onClick={() => handleRequestSort("totalSpent")}
                      >
                        {t("customers.totalSpent")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "lastOrderDate"}
                        direction={orderBy === "lastOrderDate" ? order : "asc"}
                        onClick={() => handleRequestSort("lastOrderDate")}
                      >
                        {t("customers.lastOrderDate")}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.device_id}>
                      <TableCell>{customer.full_name}</TableCell>
                      <TableCell>{customer.phone_number}</TableCell>
                      <TableCell>{customer.shipping_address}</TableCell>
                      <TableCell>{customer.total_orders}</TableCell>
                      <TableCell>
                        {config.config.currency}{Number(customer.total_spent).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {new Date(
                          customer.last_order_date
                        ).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCustomers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("pagination.rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                t("pagination.displayedRows", {
                  from,
                  to,
                  count: count !== -1 ? count : `more than ${to}`,
                })
              }
              getItemAriaLabel={(type) =>
                type === "first"
                  ? t("pagination.firstPage")
                  : type === "last"
                    ? t("pagination.lastPage")
                    : type === "next"
                      ? t("pagination.nextPage")
                      : t("pagination.previousPage")
              }
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AllCustomersPanel;
