import React, { useState } from "react";
import {
  Box,
  Typography,
  Slider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const categories = [
  "Electronics",
  "Fashion",
  "Home & Garden",
  "Sports",
  "Toys",
];
const brands = ["Brand A", "Brand B", "Brand C", "Brand D"];

function FilteringPage({ onApplyFilters, onBack }) {
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  const handleBrandChange = (event) => {
    const value = event.target.name;
    setSelectedBrands((prev) =>
      prev.includes(value)
        ? prev.filter((brand) => brand !== value)
        : [...prev, value],
    );
  };

  const applyFilters = () => {
    const filters = {
      priceRange,
      selectedCategory,
      selectedRating,
      selectedBrands,
    };
    onApplyFilters(filters);
    onBack();
  };

  return (
    <Paper
      sx={{
        padding: 3,
        margin: 3,
        borderRadius: 3,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h5">Filter Products</Typography>
        <IconButton onClick={onBack}>
          <Close />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Price Range
        </Typography>
        <Slider
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          min={0}
          max={1000}
          sx={{
            "& .MuiSlider-thumb": {
              bgcolor: "black",
            },
            "& .MuiSlider-track": {
              bgcolor: "black",
            },
          }}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <FormLabel component="legend" sx={{ mb: 1 }}>
            Category
          </FormLabel>
          <RadioGroup value={selectedCategory} onChange={handleCategoryChange}>
            {categories.map((category) => (
              <FormControlLabel
                key={category}
                value={category}
                control={<Radio />}
                label={category}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <FormLabel component="legend" sx={{ mb: 1 }}>
            Rating
          </FormLabel>
          <RadioGroup value={selectedRating} onChange={handleRatingChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="1 star & up"
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="2 stars & up"
            />
            <FormControlLabel
              value="3"
              control={<Radio />}
              label="3 stars & up"
            />
            <FormControlLabel
              value="4"
              control={<Radio />}
              label="4 stars & up"
            />
            <FormControlLabel value="5" control={<Radio />} label="5 stars" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <FormLabel component="legend" sx={{ mb: 1 }}>
            Brands
          </FormLabel>
          <List>
            {brands.map((brand) => (
              <ListItem key={brand} dense button onClick={handleBrandChange}>
                <Checkbox
                  checked={selectedBrands.includes(brand)}
                  tabIndex={-1}
                  disableRipple
                  name={brand}
                />
                <ListItemText primary={brand} />
              </ListItem>
            ))}
          </List>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={applyFilters}
          sx={{
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "black",
            },
          }}
        >
          Apply Filters
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onBack}
          sx={{
            borderColor: "primary.main",
            color: "primary.main",
            "&:hover": {
              borderColor: "primary.dark",
              color: "primary.dark",
            },
          }}
        >
          Back
        </Button>
      </Box>
    </Paper>
  );
}

export default FilteringPage;
