import axios from "axios";

const api = axios.create({
  baseURL: "https://www.ordersapps.com/api",
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await axios.post(
          "https://www.ordersapps.com/api/refresh-token",
          refreshToken,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        localStorage.setItem("access_token", response.data.access_token);
        if (response.data.refresh_token) {
          localStorage.setItem("refresh_token", response.data.refresh_token);
        }
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        // Handle refresh error (e.g., logout user)
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        // Redirect to login page or dispatch a logout action
        window.location.href = "/signin";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const cache = new Map();
const CACHE_DURATION = 60000; // 1 minute
const pendingPromises = new Map();

const fetchWithCache = async (url, options = {}) => {
  const cacheKey = `${url}${JSON.stringify(options)}`;

  // Check cache first
  const cachedData = cache.get(cacheKey);
  if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
    return cachedData.data;
  }

  // Check if there's a pending promise for this request
  if (pendingPromises.has(cacheKey)) {
    return pendingPromises.get(cacheKey);
  }

  // If not cached and not pending, make the actual API call
  const promise = api
    .get(url, options)
    .then((response) => {
      const data = response.data;
      cache.set(cacheKey, { data, timestamp: Date.now() });
      pendingPromises.delete(cacheKey);
      return data;
    })
    .catch((error) => {
      pendingPromises.delete(cacheKey);
      throw error;
    });

  pendingPromises.set(cacheKey, promise);
  return promise;
};

const fetchAnalytics = () => fetchWithCache("/analytics");

export default {
  ...api,
  fetchAnalytics,
  fetchWithCache,
};
