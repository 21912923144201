import React, { useCallback } from "react";
import { BottomNavigation, BottomNavigationAction, Badge } from "@mui/material";
import { Home, ShoppingCart, Receipt, Person } from "@mui/icons-material";
import { useSelector, shallowEqual } from "react-redux";

function FooterNavigation({ onNavigate }) {
  const [value, setValue] = React.useState(0);

  // Using shallowEqual to prevent unnecessary re-renders when totalQuantity doesn't change
  const totalQuantity = useSelector(
    (state) => state.cart.totalQuantity,
    shallowEqual,
  );

  // Memoized function to handle navigation changes
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      switch (newValue) {
        case 0:
          onNavigate(0); // Home page
          break;
        case 1:
          onNavigate(1); // Orders page
          break;
        case 2:
          onNavigate(2); // Cart page
          break;
        case 3:
          onNavigate(4); // Profile page (3 is reserved for checkout page which isn't in the footer)
          break;
        default:
          onNavigate(0); // Default to Home page
      }
    },
    [onNavigate],
  );

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #ddd",
        borderRadius: "0 0 16px 16px",
      }}
    >
      <BottomNavigationAction label="Home" icon={<Home />} />
      <BottomNavigationAction label="Orders" icon={<Receipt />} />
      <BottomNavigationAction
        label="Cart"
        icon={
          <Badge badgeContent={totalQuantity} color="error">
            <ShoppingCart />
          </Badge>
        }
      />
      <BottomNavigationAction label="Profile" icon={<Person />} />
    </BottomNavigation>
  );
}

export default React.memo(FooterNavigation);
