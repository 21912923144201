import React from "react";
import { Box, Typography } from "@mui/material";

function PromotionalBanner({ text, subtitle, color, onClick }) {
  return (
    <Box
      sx={{
        bgcolor: color,
        padding: 2,
        textAlign: "center",
        cursor: "pointer", // Add pointer cursor to indicate it's clickable
      }}
      onClick={onClick}
    >
      <Typography variant="body1">{text}</Typography>
      <Typography variant="body2">{subtitle}</Typography>
    </Box>
  );
}

export default React.memo(PromotionalBanner);
