import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Checkbox,
  IconButton,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Collapse,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import api from "../utils/api";
import ProductForm from "./ProductForm";
import FeedbackSnackbar from "./FeedbackSnackbar";

const AllProductsPanel = ({ config }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productSummaries, setProductSummaries] = useState({
    products: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [relatedProductSearch, setRelatedProductSearch] = useState("");
  const [debouncedRelatedProductSearch, setDebouncedRelatedProductSearch] =
    useState("");
  const [relatedProductOptions, setRelatedProductOptions] = useState([]);

  const fetchProductSummaries = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/products/summaries?page=${page + 1
        }&page_size=${rowsPerPage}&search=${encodeURIComponent(
          debouncedSearchTerm
        )}&sort=${orderBy}&order=${order}`
      );
      setProductSummaries(response.data);
    } catch (err) {
      setSnackbar({
        open: true,
        message: t("errors.fetchProductsFailed"),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearchTerm, orderBy, order, t]);

  useEffect(() => {
    fetchProductSummaries();
  }, [fetchProductSummaries]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await api.fetchWithCache("/categories");
      setCategories(response.categories);
    } catch (err) {
      setSnackbar({
        open: true,
        message: t("errors.fetchCategoriesFailed"),
        severity: "error",
      });
    }
  }, [t]);

  const fetchRelatedProductOptions = useCallback(
    async (search) => {
      try {
        const response = await api.get(
          `/products/summaries?page=1&page_size=20&search=${encodeURIComponent(
            search
          )}`
        );
        setRelatedProductOptions(response.data.products);
      } catch (err) {
        setSnackbar({
          open: true,
          message: t("errors.fetchRelatedProductsFailed"),
          severity: "error",
        });
      }
    },
    [t]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedRelatedProductSearch(relatedProductSearch);
    }, 300);

    return () => clearTimeout(timer);
  }, [relatedProductSearch]);

  useEffect(() => {
    if (showProductForm) {
      fetchRelatedProductOptions(debouncedRelatedProductSearch);
    }
  }, [
    showProductForm,
    debouncedRelatedProductSearch,
    fetchRelatedProductOptions,
  ]);

  useEffect(() => {
    if (showProductForm) {
      fetchCategories();
    }
  }, [showProductForm, fetchCategories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddProductClick = () => {
    setEditingProduct(null);
    setShowProductForm(true);
  };

  const handleRelatedProductSearchChange = (newValue) => {
    setRelatedProductSearch(newValue);
  };

  const handleEditProductClick = async (productId) => {
    try {
      const [productResponse, relatedProductsResponse] = await Promise.all([
        api.get(`/products/${productId}`),
        api.get(`/products/${productId}/related`),
      ]);

      const product = productResponse.data;
      product.related_product_ids = relatedProductsResponse.data.map(
        (p) => p.id
      );

      setEditingProduct(product);
      setShowProductForm(true);
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.fetchProductFailed"),
        severity: "error",
      });
    }
  };

  const handleCloneProductClick = async (productId) => {
    try {
      const [productResponse, relatedProductsResponse] = await Promise.all([
        api.get(`/products/${productId}`),
        api.get(`/products/${productId}/related`),
      ]);

      const product = productResponse.data;
      product.related_product_ids = relatedProductsResponse.data.map(
        (p) => p.id
      );

      const clonedProduct = {
        ...product,
        id: null,
        name: `${product.name} (Clone)`,
        sku: `${product.sku}-clone`,
      };

      setEditingProduct(clonedProduct);
      setShowProductForm(true);
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.fetchProductFailed"),
        severity: "error",
      });
    }
  };

  const handleProductSubmit = async (productData) => {
    try {
      let updatedProduct;
      const formData = new FormData();

      // Append the JSON stringified product data
      formData.append("product", JSON.stringify(productData));

      // Append new images
      productData.new_images.forEach((image, index) => {
        formData.append(`new_images`, image);
      });

      if (productData.id) {
        updatedProduct = await api.put(
          `/products/${productData.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setProductSummaries((prevSummaries) => ({
          ...prevSummaries,
          products: prevSummaries.products.map((p) =>
            p.id === productData.id ? { ...p, ...updatedProduct } : p
          ),
        }));
        setSnackbar({
          open: true,
          message: t("products.updateSuccess"),
          severity: "success",
        });
      } else {
        await api.post("/products", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSnackbar({
          open: true,
          message: t("products.addSuccess"),
          severity: "success",
        });
      }
      setShowProductForm(false);
      setEditingProduct(null);
      fetchProductSummaries();
    } catch (error) {
      setSnackbar({
        open: true,
        message: t(
          productData.id
            ? "errors.updateProductFailed"
            : "errors.addProductFailed"
        ),
        severity: "error",
      });
    }
  };

  const handleBackToList = () => {
    setShowProductForm(false);
    setEditingProduct(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = productSummaries.products.map(
        (product) => product.id
      );
      setSelectedProducts(newSelected);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleDeselectAll = () => {
    setSelectedProducts([]);
  };

  const handleSelectProduct = (event, id) => {
    const selectedIndex = selectedProducts.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProducts, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProducts.slice(1));
    } else if (selectedIndex === selectedProducts.length - 1) {
      newSelected = newSelected.concat(selectedProducts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProducts.slice(0, selectedIndex),
        selectedProducts.slice(selectedIndex + 1)
      );
    }

    setSelectedProducts(newSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDeleteSelected = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.post("/products/delete", { product_ids: selectedProducts });
      setProductSummaries((prevSummaries) => ({
        ...prevSummaries,
        products: prevSummaries.products.filter(
          (p) => !selectedProducts.includes(p.id)
        ),
        total: prevSummaries.total - selectedProducts.length,
      }));
      setSelectedProducts([]);
      setSnackbar({
        open: true,
        message: t("products.deleteSuccess"),
        severity: "success",
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: t("errors.deleteProductsFailed"),
        severity: "error",
      });
    }
    setDeleteDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const isSelected = (id) => selectedProducts.indexOf(id) !== -1;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleActionClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Box>
      {showProductForm ? (
        <>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBackToList}
            sx={{ mb: 2 }}
          >
            {t("common.backToList")}
          </Button>
          <ProductForm
            initialProduct={editingProduct}
            onSubmit={handleProductSubmit}
            categories={categories}
            relatedProductOptions={relatedProductOptions}
            onRelatedProductSearchChange={handleRelatedProductSearchChange}
            onCancel={handleBackToList}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h4">{t("products.allProducts")}</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddProductClick}
            >
              {t("common.create")}
            </Button>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            label={t("common.search")}
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2 }}
          />
          <Collapse in={selectedProducts.length > 0}>
            <Paper
              sx={{
                mb: 2,
                bgcolor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
              }}
            >
              <Toolbar>
                <Tooltip title={t("common.deselectAll")}>
                  <IconButton
                    onClick={handleDeselectAll}
                    color="inherit"
                    sx={{ mr: 2 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="subtitle1"
                  component="div"
                >
                  {selectedProducts.length} {t("common.selected")}
                </Typography>
                <Tooltip title={t("common.delete")}>
                  <IconButton onClick={handleDeleteSelected} color="inherit">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </Paper>
          </Collapse>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedProducts.length > 0 &&
                          selectedProducts.length <
                          productSummaries.products.length
                        }
                        checked={
                          productSummaries.products.length > 0 &&
                          selectedProducts.length ===
                          productSummaries.products.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>{t("products.photo")}</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      >
                        {t("products.name")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === "price"}
                        direction={orderBy === "price" ? order : "asc"}
                        onClick={() => handleRequestSort("price")}
                      >
                        {t("products.price")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "createdAt"}
                        direction={orderBy === "createdAt" ? order : "asc"}
                        onClick={() => handleRequestSort("createdAt")}
                      >
                        {t("common.creationDate")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{t("common.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    productSummaries.products.map((product) => {
                      const isItemSelected = isSelected(product.id);
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleSelectProduct(event, product.id)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={product.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                          <TableCell>
                            <img
                              src={
                                product.main_image || "/api/placeholder/50/50"
                              }
                              alt={product.name}
                              width="50"
                              height="50"
                            />
                          </TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell align="right">{config.config.currency}{product.price}</TableCell>
                          <TableCell>
                            {new Date(product.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <Tooltip title={t("common.view")}>
                              <Button
                                variant="text"
                                color="primary"
                                href={`/products/${product.id}`}
                                onClick={handleActionClick}
                              >
                                {t("common.view")}
                              </Button>
                            </Tooltip>
                            <Tooltip title={t("common.edit")}>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleEditProductClick(product.id);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("common.clone")}>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleCloneProductClick(product.id);
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={productSummaries.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("pagination.rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                t("pagination.displayedRows", {
                  from,
                  to,
                  count: count !== -1 ? count : `more than ${to}`,
                })
              }
              getItemAriaLabel={(type) =>
                type === "first"
                  ? t("pagination.firstPage")
                  : type === "last"
                    ? t("pagination.lastPage")
                    : type === "next"
                      ? t("pagination.nextPage")
                      : t("pagination.previousPage")
              }
            />
          </Paper>
        </>
      )}

      {/* Deletion Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("common.confirmDeleteTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("products.confirmDeleteMessage", {
              count: selectedProducts.length,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Snackbar */}
      <FeedbackSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default AllProductsPanel;
