import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import illustration from "../assets/illustration.svg";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
  Snackbar,
  Alert,
  InputAdornment,
  MenuItem,
  Grid,
  Paper,
  Link,
  Divider,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import api from "../utils/api";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgb(26, 35, 126)",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});

const currencies = [
  { value: "USD", label: "US Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "GBP", label: "British Pound" },
  { value: "JPY", label: "Japanese Yen" },
  { value: "AED", label: "درهم إماراتي (UAE Dirham)" },
  { value: "SAR", label: "ريال سعودي (Saudi Riyal)" },
  { value: "QAR", label: "ريال قطري (Qatari Riyal)" },
  { value: "OMR", label: "ريال عماني (Omani Rial)" },
  { value: "BHD", label: "دينار بحريني (Bahraini Dinar)" },
  { value: "KWD", label: "دينار كويتي (Kuwaiti Dinar)" },
  { value: "EGP", label: "جنيه مصري (Egyptian Pound)" },
  { value: "JOD", label: "دينار أردني (Jordanian Dinar)" },
  { value: "LBP", label: "ليرة لبنانية (Lebanese Pound)" },
  { value: "IQD", label: "دينار عراقي (Iraqi Dinar)" },
  { value: "TRY", label: "Türk Lirası (Turkish Lira)" },
  { value: "IRR", label: "ریال ایران (Iranian Rial)" },
  { value: "PKR", label: "Pakistani Rupee" },
  { value: "INR", label: "Indian Rupee" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "CNY", label: "人民币 (Chinese Yuan)" },
  { value: "HKD", label: "Hong Kong Dollar" },
  { value: "SGD", label: "Singapore Dollar" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "SEK", label: "Swedish Krona" },
  { value: "NZD", label: "New Zealand Dollar" },
  { value: "ZAR", label: "South African Rand" },
  { value: "RUB", label: "Российский рубль (Russian Ruble)" },
  { value: "MXN", label: "Mexican Peso" },
];

function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    subdomain: "",
    currency: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const validateField = (name, value) => {
    switch (name) {
      case "subdomain":
        if (value.length < 4) {
          return t("validation.subdomain_length");
        } else if (!/^[a-zA-Z0-9-]+$/.test(value)) {
          return t("validation.subdomain_format");
        }
        break;
      case "currency":
        if (!value) {
          return t("validation.currency_required");
        }
        break;
      case "firstName":
        if (!value) {
          return t("validation.first_name_required");
        }
        break;
      case "lastName":
        if (!value) {
          return t("validation.last_name_required");
        }
        break;
      case "email":
        if (!/\S+@\S+\.\S+/.test(value)) {
          return t("validation.email_format");
        }
        break;
      case "password":
        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            value
          )
        ) {
          return t("validation.password_requirements");
        }
        break;
      case "confirmPassword":
        if (value !== formData.password) {
          return t("validation.passwords_not_match");
        }
        break;
      default:
        if (!value) {
          return t("validation.field_required");
        }
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await api.post("/register", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        domain: `https://${formData.subdomain}.ordersapps.com`,
        currency: formData.currency,
        password: formData.password,
      });

      if (response.data.id) {
        setSnackbar({
          open: true,
          message: t("auth.registration_success"),
          severity: "success",
        });

        // If the API returns tokens upon registration, use them to log in
        if (response.data.access_token && response.data.refresh_token) {
          login(response.data.access_token, response.data.refresh_token);
          setTimeout(() => {
            navigate("/dashboard");
          }, 200);
        } else {
          // If not, redirect to signin page
          setTimeout(() => {
            navigate("/signin");
          }, 200);
        }
      } else {
        throw new Error(response.data.message || t("auth.registration_failed"));
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message ||
          error.message ||
          t("auth.error_occurred"),
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Paper elevation={3} sx={{ mt: 8, p: 4, display: "flex" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                  <LockOutlinedIcon sx={{ mr: 1, color: "primary.main" }} />
                  <Typography component="h1" variant="h4">
                    {t("auth.register")}
                  </Typography>
                </Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ width: "100%" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="subdomain"
                        label={t("auth.domain")}
                        name="subdomain"
                        autoComplete="off"
                        value={formData.subdomain}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.subdomain && !!errors.subdomain}
                        helperText={touched.subdomain && errors.subdomain}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              .ordersapps.com
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="currency"
                        select
                        label={t("auth.currency")}
                        name="currency"
                        value={formData.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.currency && !!errors.currency}
                        helperText={touched.currency && errors.currency}
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="firstName"
                        label={t("auth.first_name")}
                        name="firstName"
                        autoComplete="given-name"
                        value={formData.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && !!errors.firstName}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label={t("auth.last_name")}
                        name="lastName"
                        autoComplete="family-name"
                        value={formData.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && !!errors.lastName}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label={t("auth.email")}
                        name="email"
                        autoComplete="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label={t("auth.password")}
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label={t("auth.confirm_password")}
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.confirmPassword && !!errors.confirmPassword
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, py: 1.5 }}
                    disabled={isLoading}
                  >
                    {isLoading ? t("auth.registering") : t("auth.register")}
                  </Button>
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Link
                      component={RouterLink}
                      to="/signin"
                      variant="body2"
                    >
                      {t("auth.existing_user")} {t("auth.login")}
                    </Link>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      component={RouterLink}
                      to="/"
                      startIcon={<HomeIcon />}
                      variant="outlined"
                    >
                      {t("general.back_to_home")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="h5" gutterBottom>
                  {t("illustration.signup_title")}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t("illustration.signup_description")}
                </Typography>
                <Box
                  component="img"
                  src={illustration}
                  alt={t("illustration.signup_alt")}
                  sx={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default SignUp;
