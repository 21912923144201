import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalQuantity: 0,
  buyNowItems: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      if (existingItem) {
        existingItem.quantity += newItem.quantity;
      } else {
        state.items.push(newItem);
      }
      state.totalQuantity += newItem.quantity;
    },
    removeItemFromCart: (state, action) => {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        state.totalQuantity -= existingItem.quantity;
        state.items = state.items.filter((item) => item.id !== id);
      }
    },
    addItemToBuyNow: (state, action) => {
      state.buyNowItems = [action.payload];
    },
    clearBuyNow: (state) => {
      state.buyNowItems = [];
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  addItemToBuyNow,
  clearBuyNow,
} = cartSlice.actions;

export default cartSlice.reducer;
