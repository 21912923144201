import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { Icon } from "@mui/material"; // Using Material-UI icons

export const FeatureCard = ({ feature }) => {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center the content horizontally
        borderRadius: "20px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
        },
        padding: "30px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          backgroundColor: "#1a237e", // Icon background color
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Icon
          component={feature.icon}
          sx={{
            color: "#fff",
            fontSize: "40px",
          }}
        />
      </Box>
      <CardContent sx={{ padding: "0" }}>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontWeight: "bold",
            marginBottom: "15px",
            color: "#1a237e",
          }}
        >
          {feature.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {feature.description}
        </Typography>
      </CardContent>
    </Card>
  );
};
