const themes = {
  light: {
    bgColor: "#ffffff",
    textColor: "#000000",
    primaryColor: "#1976d2",
    secondaryColor: "#ff4081",
  },
  dark: {
    bgColor: "#333333",
    textColor: "#ffffff",
    primaryColor: "#bb86fc",
    secondaryColor: "#03dac6",
  },
  blue: {
    bgColor: "#e3f2fd",
    textColor: "#0d47a1",
    primaryColor: "#2196f3",
    secondaryColor: "#ff9800",
  },
  green: {
    bgColor: "#e8f5e9",
    textColor: "#1b5e20",
    primaryColor: "#4caf50",
    secondaryColor: "#f44336",
  },
};

export default themes;
