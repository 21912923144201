import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import {
  Add,
  Remove,
  Delete,
  ShoppingCart,
  ShoppingBasket,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart, removeItemFromCart } from "../../slices/cartSlice";

function Cart({ onProductClick, onCheckout, onNavigate }) {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleQuantityChange = (id, delta) => {
    const item = cartItems.find((item) => item.id === id);
    if (item) {
      const newQuantity = item.quantity + delta;
      if (newQuantity > 0) {
        dispatch(addItemToCart({ ...item, quantity: delta }));
      }
    }
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  const parsePrice = (price) => parseFloat(price.replace(/[^0-9.-]+/g, ""));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "#fff",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <Box sx={{ padding: 2, borderBottom: "1px solid #ddd" }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Cart
        </Typography>
      </Box>
      {cartItems.length > 0 ? (
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: 2,
          }}
        >
          {cartItems.map((item) => {
            const price = parsePrice(item.price);
            return (
              <Card
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                  boxShadow: "none",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "stretch" }}>
                  <CardMedia
                    component="img"
                    image={item.images[0]}
                    alt={item.title}
                    sx={{ width: 100, cursor: "pointer" }}
                    onClick={() => onProductClick(item)}
                  />
                  <CardContent sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">{item.title}</Typography>
                      <Typography variant="h6">${price.toFixed(2)}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleQuantityChange(item.id, -1)}
                      >
                        <Remove fontSize="small" />
                      </IconButton>
                      <Typography variant="body1" sx={{ mx: 1 }}>
                        {item.quantity}
                      </Typography>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleQuantityChange(item.id, 1)}
                      >
                        <Add fontSize="small" />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        <Delete fontSize="small" />
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
                <Divider />
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="subtitle1">Subtotal</Typography>
                  <Typography variant="h6">
                    ${(price * item.quantity).toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            textAlign: "center",
          }}
        >
          <ShoppingBasket sx={{ fontSize: 80, color: "gray", mb: 2 }} />
          <Typography variant="h5" gutterBottom>
            Your cart is empty
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Looks like you haven't added anything to your cart yet.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              bgcolor: "black",
              color: "white",
              "&:hover": { bgcolor: "black" },
            }}
            onClick={() => onNavigate(0)}
          >
            Continue Shopping
          </Button>
        </Box>
      )}
      {cartItems.length > 0 && (
        <Box sx={{ padding: 2, borderTop: "1px solid #ddd" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Total</Typography>
            <Typography variant="h6">
              $
              {cartItems
                .reduce(
                  (total, item) =>
                    total + parsePrice(item.price) * item.quantity,
                  0,
                )
                .toFixed(2)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShoppingCart />}
            sx={{
              bgcolor: "black",
              color: "white",
              "&:hover": { bgcolor: "black" },
            }}
            fullWidth
            onClick={onCheckout}
          >
            Checkout
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Cart;
