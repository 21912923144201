import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Link,
  IconButton,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/system";

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a237e",
  color: "#fff",
  padding: theme.spacing(6, 0),
  marginTop: theme.spacing(8),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#ffffff",
  textDecoration: "none",
  marginBottom: theme.spacing(1),
  display: "block",
  "&:hover": {
    textDecoration: "underline",
  },
}));

function Footer() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem",
                },
              }}
            >
              {t("general.app_name")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: theme.spacing(2.5),
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              }}
            >
              {t("general.description")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.75rem",
                },
              }}
            >
              &copy; {new Date().getFullYear()} Orders Apps | Netherlands -
              Amsterdam
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem",
                },
              }}
            >
              {t("contact.cta_contact_us")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              }}
            >
              {t("contact.email")}:{" "}
              <FooterLink href="mailto:info@orders-apps.com">
                info@orders-apps.com
              </FooterLink>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              }}
            >
              <Typography>{t("contact.chat")}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem",
                },
              }}
            >
              {t("pages.title")}
            </Typography>
            <FooterLink href="/">{t("pages.main")}</FooterLink>
            <FooterLink href="/signup">{t("pages.register")}</FooterLink>
            <FooterLink href="/blog">{t("pages.blog")}</FooterLink>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <IconButton
            href="https://facebook.com"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            href="https://twitter.com"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <Twitter />
          </IconButton>
          <IconButton
            href="https://instagram.com"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            href="https://linkedin.com"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <LinkedIn />
          </IconButton>
          <IconButton
            href="https://youtube.com"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <YouTube />
          </IconButton>
        </Box>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
