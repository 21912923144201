import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import PricingSection from "../components/PricingSection";
import QASection from "../components/QASection";
import PlatformsShowcase from "../components/PlatformsShowcase";
import Footer from "../components/Footer";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("seo.home.title")}</title>
        <meta name="description" content={t("seo.home.description")} />
        <meta name="keywords" content={t("seo.home.keywords")} />
        <link rel="canonical" href="https://ordersapps.com" />
      </Helmet>
      <Header />
      <main>
        <HeroSection />
        <FeaturesSection />
        <PlatformsShowcase />
        <PricingSection />
        <QASection />
      </main>
      <Footer />
    </>
  );
}

export default Home;
