import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import { Tune, Search } from "@mui/icons-material";

function Header({ onNavigate }) {
  // Receive onNavigate prop
  return (
    <AppBar position="sticky" color="default" sx={{ boxShadow: "none" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="settings"
            onClick={() => onNavigate(5)} // Navigate to FilteringPage
          >
            <Tune />
          </IconButton>
          <TextField
            variant="outlined"
            placeholder="Search"
            size="small"
            sx={{
              marginLeft: 2,
              flexGrow: 1,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
                "& fieldset": {
                  borderColor: "#ddd",
                },
                "&:hover fieldset": {
                  borderColor: "#ccc",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#aaa",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "8px 14px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
