import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CategoryForm = ({ open, onClose, onSave, category }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    if (category) {
      setName(category.name);
      setPreviewUrl(category.icon_url || "");
    } else {
      setName("");
      setPreviewUrl("");
    }
    setIcon(null);
  }, [category]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("category", JSON.stringify({ name }));
    if (icon) {
      formData.append("icon", icon);
    }
    onSave(formData);
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIcon(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveIcon = () => {
    setIcon(null);
    setPreviewUrl("");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {category
            ? t("categories.editCategory")
            : t("categories.createCategory")}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 3 }}>
            <TextField
              autoFocus
              label={t("categories.name")}
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t("categories.categoryIcon")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ mr: 2 }}
              >
                {t("categories.uploadIcon")}
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </Button>
              {previewUrl && (
                <IconButton onClick={handleRemoveIcon} color="error">
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          {previewUrl && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <img
                src={previewUrl}
                alt="Icon preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={onClose}>{t("common.cancel")}</Button>
          <Button type="submit" variant="contained" color="primary">
            {category ? t("common.save") : t("common.create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CategoryForm;
