import React, { useState, useCallback, useMemo } from "react";
import { Box, Typography, Grid, IconButton, Button } from "@mui/material";
import { Add, Remove, ArrowBack, ShoppingCart } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../slices/cartSlice";

const products = [
  {
    id: 1,
    title: "Product 1",
    price: "$10.00",
    category: 1,
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
  },
  {
    id: 2,
    title: "Product 2",
    price: "$15.00",
    category: 2,
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
  },
  {
    id: 3,
    title: "Product 3",
    price: "$20.00",
    category: 3,
    images: [
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
      "https://via.placeholder.com/320x180",
    ],
  },
  // Add more products if needed to ensure scrolling
];

function CategoryDetail({ category, onProductClick, onAddToCart, onBack }) {
  const [productQuantities, setProductQuantities] = useState({});
  const dispatch = useDispatch();

  const categoryProducts = useMemo(
    () => products.filter((product) => product.category === category.id),
    [category.id],
  );

  const handleQuantityChange = useCallback((id, delta) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max(1, (prevQuantities[id] || 1) + delta),
    }));
  }, []);

  const handleAddToCart = useCallback(
    (product) => {
      const quantity = productQuantities[product.id] || 1;
      dispatch(addItemToCart({ ...product, quantity }));
      onAddToCart(product, quantity);
    },
    [dispatch, onAddToCart, productQuantities],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        bgcolor: "#fff",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onBack} sx={{ padding: 0 }}>
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, textAlign: "center", marginRight: "32px" }}
        >
          {category.label}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {categoryProducts.map((product) => (
          <Grid item xs={6} key={product.id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "#f8f8f8",
                borderRadius: 2,
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={() => onProductClick(product)}
            >
              <img
                src={product.images[0]}
                alt={product.title}
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {product.title}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  {product.price}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityChange(product.id, -1);
                    }}
                  >
                    <Remove fontSize="small" />
                  </IconButton>
                  <Typography variant="body1">
                    {productQuantities[product.id] || 1}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityChange(product.id, 1);
                    }}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: "40px",
                      height: "40px",
                      padding: 0,
                      fontSize: "0.875rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "black",
                      color: "white",
                      "&:hover": { bgcolor: "black" },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(product);
                    }}
                  >
                    <ShoppingCart fontSize="small" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(CategoryDetail);
