import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Box, Typography, Button, IconButton } from "@mui/material";
import {
  Add,
  Remove,
  ArrowBackIos,
  ArrowForwardIos,
  ArrowBack,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addItemToCart, addItemToBuyNow } from "../../slices/cartSlice";

const ProductDetail = React.memo(
  ({ product, onAddToCart, onBack, onBuyNow }) => {
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();

    const handleQuantityChange = useCallback((delta) => {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity + delta));
    }, []);

    const handleAddToCart = useCallback(() => {
      dispatch(addItemToCart({ ...product, quantity }));
      onAddToCart(product, quantity);
    }, [dispatch, product, quantity, onAddToCart]);

    const handleBuyNow = useCallback(() => {
      dispatch(addItemToBuyNow({ ...product, quantity }));
      onBuyNow();
    }, [dispatch, product, quantity, onBuyNow]);

    const settings = useMemo(
      () => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIos />,
        prevArrow: <ArrowBackIos />,
      }),
      [],
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          bgcolor: "#fff",
          borderRadius: 2,
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton onClick={onBack} sx={{ padding: 0, marginRight: 1 }}>
            <ArrowBack />
          </IconButton>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Slider {...settings}>
            {product.images.map((image, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                <img
                  src={image}
                  alt={product.title}
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </Box>
            ))}
          </Slider>
        </Box>
        <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
          {product.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ mr: 2 }}>
            {product.price}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "black",
              color: "white",
              "&:hover": { bgcolor: "black" },
              width: "150px",
            }}
            onClick={handleAddToCart}
          >
            Add to Cart
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleQuantityChange(-1)}
            >
              <Remove fontSize="small" />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 1 }}>
              {quantity}
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleQuantityChange(1)}
            >
              <Add fontSize="small" />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              bgcolor: "darkgrey",
              color: "white",
              "&:hover": { bgcolor: "grey" },
              width: "150px",
            }}
            onClick={handleBuyNow}
          >
            Buy Now
          </Button>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {product.description}
        </Typography>
      </Box>
    );
  },
);

ProductDetail.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onBuyNow: PropTypes.func.isRequired,
};

export default ProductDetail;
