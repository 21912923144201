import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const pricingPlans = [
  {
    title: "pricing.lowest_price_description",
    price: "$0.02",
    subtitle: "pricing.lowest_price_title",
    details: [
      "features.unlimited_orders",
      "features.unlimited_products",
      "features.unlimited_visits",
      "features.lifetime_access",
    ],
    buttonText: "general.cta_start_now",
    isHighlighted: true,
  },
  // Add more plans here
];

function PricingSection() {
  const { t } = useTranslation();
  return (
    <Box component="section" sx={{ py: 8, backgroundColor: "#ffffff" }}>
      <Container>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            marginBottom: "40px",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }
          }}
        >
          {t("pricing.title")}
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: "20px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
                  },
                  border: plan.isHighlighted
                    ? "2px solid #1a237e"
                    : "none",
                }}
              >
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{
                      color: plan.isHighlighted ? "#1a237e" : "text.primary",
                      fontWeight: "bold",
                    }}
                  >
                    {t(plan.subtitle)}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    gutterBottom
                    sx={{ color: "#757575", fontWeight: "normal" }}
                  >
                    {t(plan.title)}
                  </Typography>
                  <Typography
                    variant="h3"
                    align="center"
                    color="primary"
                    gutterBottom
                    sx={{
                      color: plan.isHighlighted ? "#1a237e" : "primary.main",
                      fontWeight: "bold",
                    }}
                  >
                    {plan.price}
                  </Typography>
                  <Box component="ul" sx={{
                    listStyleType: "none",
                    padding: 0,
                    marginBottom: "20px",
                    flexGrow: 1,
                  }}>
                    {plan.details.map((detail, index) => (
                      <Typography
                        key={index}
                        component="li"
                        variant="body2"
                        align="center"
                        sx={{ color: "#757575", marginBottom: 1 }}
                      >
                        {t(detail)}
                      </Typography>
                    ))}
                  </Box>
                  <Box textAlign="center" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        backgroundColor: plan.isHighlighted
                          ? "#1a237e"
                          : "#3f51b5",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: plan.isHighlighted
                            ? "#283593"
                            : "#303f9f",
                        },
                        borderRadius: "10px",
                        padding: "10px 20px",
                        fontWeight: "bold",
                      }}
                    >
                      {t(plan.buttonText)}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PricingSection;
