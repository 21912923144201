import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  TableSortLabel,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import api from "../utils/api";
import FeedbackSnackbar from "./FeedbackSnackbar";
import CategoryForm from "./CategoryForm";

const CategoriesPanel = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [openCategoryForm, setOpenCategoryForm] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [totalCategories, setTotalCategories] = useState(0);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/categories?page=${page + 1
        }&page_size=${rowsPerPage}&search=${searchTerm}&sort=${orderBy}&order=${order}`
      );
      setCategories(response.data.categories);
      setTotalCategories(response.data.total);
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.fetchCategoriesFailed"),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [t, page, rowsPerPage, searchTerm, orderBy, order]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreateCategory = () => {
    setEditingCategory(null);
    setOpenCategoryForm(true);
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setOpenCategoryForm(true);
  };

  const handleCloseCategoryForm = () => {
    setOpenCategoryForm(false);
    setEditingCategory(null);
  };

  const handleSaveCategory = async (formData) => {
    try {
      if (editingCategory) {
        await api.put(`/categories/${editingCategory.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setSnackbar({
          open: true,
          message: t("categories.updateSuccess"),
          severity: "success",
        });
      } else {
        await api.post("/categories", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setSnackbar({
          open: true,
          message: t("categories.createSuccess"),
          severity: "success",
        });
      }
      fetchCategories();
      handleCloseCategoryForm();
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.saveCategoryFailed"),
        severity: "error",
      });
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">{t("categories.title")}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateCategory}
        >
          {t("common.create")}
        </Button>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        label={t("common.search")}
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 2 }}
      />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleRequestSort("name")}
                  >
                    {t("categories.name")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("categories.icon")}</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "product_count"}
                    direction={orderBy === "product_count" ? order : "asc"}
                    onClick={() => handleRequestSort("product_count")}
                  >
                    {t("categories.productCount")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                  >
                    {t("common.creationDate")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("common.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                categories.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      {category.icon_url && (
                        <img
                          src={category.icon_url}
                          alt={category.name}
                          width="50"
                          height="50"
                        />
                      )}
                    </TableCell>
                    <TableCell>{category.product_count}</TableCell>
                    <TableCell>
                      {new Date(category.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={t("common.edit")}>
                        <EditIcon
                          onClick={() => handleEditCategory(category)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCategories}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            t("pagination.displayedRows", {
              from,
              to,
              count: count !== -1 ? count : `more than ${to}`,
            })
          }
          getItemAriaLabel={(type) =>
            type === "first"
              ? t("pagination.firstPage")
              : type === "last"
                ? t("pagination.lastPage")
                : type === "next"
                  ? t("pagination.nextPage")
                  : t("pagination.previousPage")
          }
        />
      </Paper>

      <CategoryForm
        open={openCategoryForm}
        onClose={handleCloseCategoryForm}
        onSave={handleSaveCategory}
        category={editingCategory}
      />

      <FeedbackSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </Box>
  );
};

export default CategoriesPanel;
