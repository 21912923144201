import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Paper } from "@mui/material";

const MetricCard = ({ title, value, icon }) => {
  const { t } = useTranslation();
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t(title)}
        </Typography>
        <Typography variant="h4" color="primary">
          {value}
        </Typography>
      </Box>
      {icon}
    </Paper>
  );
};

export default MetricCard;
