import React, { useMemo } from "react";
import { Box, ThemeProvider, createTheme, keyframes } from "@mui/material";
import Template from "./ecommerce/Template";
import themes from "../themes";

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg) scale(1.05); }
  10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.05); }
  20% { transform: translate(-3px, 0px) rotate(1deg) scale(1.05); }
  30% { transform: translate(3px, 2px) rotate(0deg) scale(1.05); }
  40% { transform: translate(1px, -1px) rotate(1deg) scale(1.05); }
  50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.05); }
  60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.05); }
  70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.05); }
  80% { transform: translate(-1px, -1px) rotate(1deg) scale(1.05); }
  90% { transform: translate(1px, 2px) rotate(0deg) scale(1.05); }
  100% { transform: translate(1px, -2px) rotate(-1deg) scale(1.05); }
`;

function MobileScreenContainer({
  components,
  productCardSize,
  selectedTheme,
  hoveredComponent,
  currentPage,
  onNavigate,
}) {
  const themeConfig = useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: themes[selectedTheme].bgColor,
          },
          text: {
            primary: themes[selectedTheme].textColor,
          },
          primary: {
            main: themes[selectedTheme].primaryColor,
          },
          secondary: {
            main: themes[selectedTheme].secondaryColor,
          },
        },
      }),
    [selectedTheme],
  );

  return (
    <Box
      sx={{
        bgcolor: "#e0e0e0",
        height: "95vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 360,
          height: "100%",
          bgcolor: themes[selectedTheme].bgColor,
          borderRadius: 16,
          overflow: "hidden",
          border: "1px solid #ddd",
          position: "relative",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ThemeProvider theme={themeConfig}>
          <Template
            styles={themes[selectedTheme]}
            components={components}
            productCardSize={productCardSize}
            hoveredComponent={hoveredComponent}
            currentPage={currentPage}
            onNavigate={onNavigate}
            highlightStyle={{
              animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
              transform: "translate3d(0, 0, 0)",
              backfaceVisibility: "hidden",
              perspective: 1000,
              boxShadow: "0 0 10px 5px rgba(0, 0, 255, 0.5)",
            }}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
}

export default MobileScreenContainer;
