import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import heroImage from "../assets/hero.jpg";
import { useTranslation } from "react-i18next";

function HeroSection() {
  const { t } = useTranslation();
  return (
    <Box
      component="section"
      sx={{
        position: "relative",
        height: "80vh",
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        textAlign: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
        },
      }}
    >
      <Container sx={{ position: "relative", zIndex: 1 }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
            marginBottom: "20px",
            fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
          }}
        >
          {t("hero.title")}
        </Typography>
        <Typography
          variant="h2"
          component="p"
          gutterBottom
          sx={{
            fontWeight: "400",
            maxWidth: "600px",
            margin: "0 auto 30px",
            textShadow: "1px 1px 3px rgba(0,0,0,0.5)",
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
          }}
        >
          {t("hero.description")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/signup"
          sx={{
            backgroundColor: "#1a237e",
            padding: "10px 30px",
            fontSize: "18px",
            fontWeight: "bold",
            borderRadius: "30px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#3c4fc7",
            },
          }}
        >
          {t("general.cta_start_now")}
        </Button>
      </Container>
    </Box>
  );
}

export default HeroSection;
