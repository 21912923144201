import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Rating,
  TableSortLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import api from "../utils/api";
import FeedbackSnackbar from "./FeedbackSnackbar";

const ReviewsPanel = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [openReviewForm, setOpenReviewForm] = useState(false);
  const [newReview, setNewReview] = useState({
    product_id: "",
    user_name: "",
    rating: 0,
    review: "",
  });
  const [productOptions, setProductOptions] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const fetchReviews = useCallback(async () => {
    try {
      const response = await api.get(
        `/reviews?page=${page + 1
        }&page_size=${rowsPerPage}&search=${searchTerm}&sort=${orderBy}&order=${order}`
      );
      setReviews(response.data.reviews);
      setTotalReviews(response.data.total);
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.fetchReviewsFailed"),
        severity: "error",
      });
    }
  }, [page, rowsPerPage, debouncedSearchTerm, orderBy, order]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value);
    }, 300),
    []
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const fetchProductOptions = useCallback(
    async (search = "") => {
      try {
        const response = await api.get(
          `/products/summaries?page=1&page_size=20&search=${encodeURIComponent(
            search
          )}`
        );
        setProductOptions(response.data.products);
      } catch (error) {
        setSnackbar({
          open: true,
          message: t("errors.fetchProductOptionsFailed"),
          severity: "error",
        });
      }
    },
    [t]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreateReview = () => {
    setOpenReviewForm(true);
    fetchProductOptions(); // Fetch product options when the form is opened
  };

  const handleCloseReviewForm = () => {
    setOpenReviewForm(false);
    setNewReview({
      product_id: "",
      user_name: "",
      rating: 0,
      review: "",
    });
    setProductSearch("");
    setProductOptions([]); // Clear product options when closing the form
  };

  const handleSubmitReview = async () => {
    try {
      await api.post("/reviews", newReview);
      setSnackbar({
        open: true,
        message: t("reviews.addSuccess"),
        severity: "success",
      });
      handleCloseReviewForm();
      fetchReviews();
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.addReviewFailed"),
        severity: "error",
      });
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUpdateReviewStatus = async (reviewId, newStatus) => {
    try {
      await api.put(`/reviews/${reviewId}/manage`, { active: newStatus });
      setSnackbar({
        open: true,
        message: t("reviews.updateSuccess"),
        severity: "success",
      });
      fetchReviews();
    } catch (error) {
      setSnackbar({
        open: true,
        message: t("errors.updateReviewFailed"),
        severity: "error",
      });
    }
  };

  const debouncedProductSearch = useCallback(
    debounce((search) => {
      fetchProductOptions(search);
    }, 300),
    [fetchProductOptions]
  );

  const handleProductSearchChange = (event, newInputValue) => {
    setProductSearch(newInputValue);
    debouncedProductSearch(newInputValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">{t("products.reviews")}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateReview}
        >
          {t("common.create")}
        </Button>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        label={t("common.search")}
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 2 }}
      />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "product_name"}
                    direction={orderBy === "product_name" ? order : "asc"}
                    onClick={() => handleRequestSort("product_name")}
                  >
                    {t("reviews.productName")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "rating"}
                    direction={orderBy === "rating" ? order : "asc"}
                    onClick={() => handleRequestSort("rating")}
                  >
                    {t("reviews.rating")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("reviews.comment")}</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                  >
                    {t("reviews.date")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("reviews.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews.map((review) => (
                <TableRow key={review.id}>
                  <TableCell>{review.product_name}</TableCell>
                  <TableCell>
                    <Rating value={review.rating} readOnly />
                  </TableCell>
                  <TableCell>{review.review}</TableCell>
                  <TableCell>
                    {new Date(review.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={review.active}
                      onChange={(e) =>
                        handleUpdateReviewStatus(review.id, e.target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalReviews}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            t("pagination.displayedRows", {
              from,
              to,
              count: count !== -1 ? count : `more than ${to}`,
            })
          }
          getItemAriaLabel={(type) =>
            type === "first"
              ? t("pagination.firstPage")
              : type === "last"
                ? t("pagination.lastPage")
                : type === "next"
                  ? t("pagination.nextPage")
                  : t("pagination.previousPage")
          }
        />
      </Paper>

      {/* Review Form Dialog */}
      <Dialog
        open={openReviewForm}
        onClose={handleCloseReviewForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{t("reviews.addReview")}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t("reviews.selectProductInstruction")}
            </Typography>
            <Autocomplete
              value={
                productOptions.find(
                  (option) => option.id === newReview.product_id
                ) || null
              }
              onChange={(event, newValue) => {
                setNewReview({
                  ...newReview,
                  product_id: newValue ? newValue.id : "",
                });
              }}
              inputValue={productSearch}
              onInputChange={handleProductSearchChange}
              options={productOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("reviews.selectProduct")}
                  fullWidth
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Box>
          <TextField
            fullWidth
            margin="normal"
            label={t("reviews.userName")}
            value={newReview.user_name}
            onChange={(e) =>
              setNewReview({ ...newReview, user_name: e.target.value })
            }
          />
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography component="legend">
              {t("reviews.ratingInstruction")}
            </Typography>
            <Rating
              name="rating"
              value={newReview.rating}
              onChange={(e, newValue) =>
                setNewReview({ ...newReview, rating: newValue })
              }
              size="large"
            />
          </Box>
          <TextField
            fullWidth
            margin="normal"
            label={t("reviews.comment")}
            multiline
            rows={6}
            value={newReview.review}
            onChange={(e) =>
              setNewReview({ ...newReview, review: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleCloseReviewForm} variant="outlined">
            {t("common.cancel")}
          </Button>
          <Button
            onClick={handleSubmitReview}
            variant="contained"
            color="primary"
          >
            {t("common.create")}
          </Button>
        </DialogActions>
      </Dialog>

      <FeedbackSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </Box>
  );
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default ReviewsPanel;
