import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  Drawer,
  IconButton,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import MobileScreenContainer from "../components/MobileScreenContainer";
import StylingOptionsSidebar from "../components/StylingOptionsSidebar";
import { useAuth } from "../contexts/AuthContext";
import api from "../utils/api";

const initialComponents = [
  "Header",
  "PromotionalBanner",
  "CategoryList",
  "ProductList",
  "FooterNavigation",
];

function Playground() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, logout } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [components, setComponents] = useState(initialComponents);
  const [productCardSize, setProductCardSize] = useState("medium");
  const [selectedTheme, setSelectedTheme] = useState("light");
  const [hoveredComponent, setHoveredComponent] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [componentPositions, setComponentPositions] = useState(
    initialComponents.reduce((acc, component, index) => {
      acc[component] = index;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/signin");
    }
  }, [isAuthenticated, isLoading, navigate]);

  const handleRemoveComponent = (component) => {
    setComponents((prevComponents) =>
      prevComponents.filter((c) => c !== component)
    );
  };

  const handleAddComponent = (component) => {
    if (!components.includes(component)) {
      setComponents((prevComponents) => {
        const newComponents = [...prevComponents, component];
        return newComponents.sort(
          (a, b) => componentPositions[a] - componentPositions[b]
        );
      });
    }
  };

  const handleProductCardSizeChange = (size) => {
    setProductCardSize(size);
  };

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
  };

  const handleComponentHover = (component) => {
    setHoveredComponent(component);
  };

  const handleNavigate = (page) => {
    setCurrentPage(page);
  };

  const serializeAppState = () => {
    return JSON.stringify({
      components,
      productCardSize,
      selectedTheme,
      currentPage,
    });
  };

  const sendDesignToBackend = async () => {
    const designState = serializeAppState();
    try {
      const response = await api.post("/generate-flutter-app", designState);
      console.log("Backend response:", response.data);
    } catch (error) {
      console.error("Error sending design to backend:", error);
      if (error.response && error.response.status === 401) {
        // Token refresh should be handled automatically by the api instance
        // If we still get here, it means refresh failed
        logout();
        navigate("/signin");
      }
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const sidebarContent = (
    <Box sx={{ width: 300, height: "100%", overflow: "auto", p: 1.5 }}>
      <Typography variant="h6" component="h1" gutterBottom>
        App Designer
      </Typography>
      <StylingOptionsSidebar
        components={components}
        onRemoveComponent={handleRemoveComponent}
        onAddComponent={handleAddComponent}
        productCardSize={productCardSize}
        onProductCardSizeChange={handleProductCardSizeChange}
        selectedTheme={selectedTheme}
        onThemeChange={handleThemeChange}
        onComponentHover={handleComponentHover}
        currentPage={currentPage}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2, bgcolor: "#1e36a5", "&:hover": { bgcolor: "#162a7e" } }}
        onClick={sendDesignToBackend}
      >
        Generate Flutter App
      </Button>
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          logout();
          navigate("/signin");
        }}
      >
        Logout
      </Button>
    </Box>
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#e0e0e0",
          p: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        {isMobile && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ position: "absolute", top: 16, right: 16, color: "#1e36a5" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
        <MobileScreenContainer
          components={components}
          productCardSize={productCardSize}
          selectedTheme={selectedTheme}
          hoveredComponent={hoveredComponent}
          currentPage={currentPage}
          onNavigate={handleNavigate}
        />
      </Box>
      {!isMobile && (
        <Box
          sx={{
            width: 330,
            bgcolor: "#f5f5f5",
            overflow: "auto",
            borderLeft: 1,
            borderColor: "divider",
          }}
        >
          {sidebarContent}
        </Box>
      )}
      {isMobile && (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {sidebarContent}
        </Drawer>
      )}
    </Box>
  );
}

export default Playground;
