import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FacebookIcon from "@mui/icons-material/Facebook";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useTranslation } from "react-i18next";

const PlatformsShowcase = () => {
  const { t } = useTranslation();

  const platforms = [
    {
      title: "platforms.store.title",
      description: "platforms.store.description",
      icon: <StorefrontIcon fontSize="large" />,
    },
    {
      title: "platforms.social.title",
      description: "platforms.social.description",
      icon: <FacebookIcon fontSize="large" />,
    },
    {
      title: "platforms.google.title",
      description: "platforms.google.description",
      icon: <ShoppingBasketIcon fontSize="large" />,
    },
  ];

  return (
    <Box component="section" sx={{ bgcolor: "background.paper", py: 8 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          component="h2" 
          align="center" 
          gutterBottom
          sx={{ 
            fontWeight: "bold",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            marginBottom: "40px"
          }}
        >
          {t("platforms.mainTitle")}
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        {platforms.map((platform, index) => (
            <Accordion 
              key={index}
              sx={{
                '&:not(:last-child)': {
                  borderBottom: '1px solid rgba(0, 0, 0, .125)',
                },
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, .03)',
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                  },
                  '& .MuiAccordionSummary-content': {
                    marginLeft: 2,
                  },
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>{platform.icon}</Grid>
                  <Grid item>
                    <Typography variant="h6">{t(platform.title)}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{t(platform.description)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default PlatformsShowcase;
