import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../utils/api";

const OrderDetailsPanel = ({ orderId, onClose, config }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await api.get(`/orders/domain/${orderId}`);
        setOrder(response.data);
        setNewStatus(response.data.status);
        setEstimatedDeliveryDate(
          response.data.estimated_delivery_date
            ? new Date(response.data.estimated_delivery_date)
            : null
        );
      } catch (error) {
        setError(t("errors.failedToFetchOrderDetails"));
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId, t]);

  const handleStatusUpdate = async () => {
    try {
      const formattedDate = estimatedDeliveryDate
        ? estimatedDeliveryDate.toISOString().split(".")[0]
        : null;
      await api.put(`/orders/${orderId}/status`, {
        status: newStatus,
        estimated_delivery_date: formattedDate,
      });
      setOrder({
        ...order,
        status: newStatus,
        estimated_delivery_date: formattedDate,
      });
      setSnackbarMessage(t("orders.statusUpdateSuccess"));
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage(t("errors.failedToUpdateOrderStatus"));
      setSnackbarSeverity("error");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  if (loading) return <Typography>{t("common.loading")}</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!order) return <Typography>{t("orders.notFound")}</Typography>;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">{t("orders.orderDetails")}</Typography>
        <Button onClick={onClose}>{t("common.close")}</Button>
      </Box>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          {/* Order ID */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">{t("orders.orderId")}</Typography>
            <Typography variant="body1">{order.id}</Typography>
          </Grid>

          {/* Customer Name */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.customerName")}
            </Typography>
            <Typography variant="body1">{order.user_data.fullName}</Typography>
          </Grid>

          {/* Order Date */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">{t("orders.orderDate")}</Typography>
            <Typography variant="body1">
              {new Date(order.created_at).toLocaleString()}
            </Typography>
          </Grid>

          {/* Status */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">{t("orders.status")}</Typography>
            <Chip
              label={t(`orders.statuses.${order.status.toLowerCase()}`)}
              color="primary"
            />
          </Grid>

          {/* Total */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">{t("orders.total")}</Typography>
            <Typography variant="body1">{config.config.currency}{Number(order.total).toFixed(2)}</Typography>
          </Grid>

          {/* Payment Method */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.paymentMethod")}
            </Typography>
            <Typography variant="body1">{order.payment_method}</Typography>
          </Grid>

          {/* Transaction ID */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.transactionId")}
            </Typography>
            <Typography variant="body1">
              {order.transaction_id || "-"}
            </Typography>
          </Grid>

          {/* Shipping Region */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.shippingRegion")}
            </Typography>
            <Typography variant="body1">
              {order.shipping_region_name}
            </Typography>
          </Grid>

          {/* Shipping Cost */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.shippingCost")}
            </Typography>
            <Typography variant="body1">{config.config.currency}{Number(order.shipping_cost).toFixed(2)}</Typography>
          </Grid>

          {/* User Data */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.phoneNumber")}
            </Typography>
            <Typography variant="body1">
              {order.user_data.phoneNumber}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t("orders.shippingAddress")}
            </Typography>
            <Typography variant="body1">
              {order.user_data.shippingAddress}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper} elevation={3} sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("orders.product")}</TableCell>
              <TableCell align="right">{t("orders.quantity")}</TableCell>
              <TableCell align="right">{t("orders.price")}</TableCell>
              <TableCell align="right">{t("orders.subtotal")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.product_name}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{config.config.currency}{Number(item.price).toFixed(2)}</TableCell>
                <TableCell align="right">
                {config.config.currency}{(item.quantity * item.price).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {order.status !== "DELIVERED" && order.status !== "CANCELLED" && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" mb={2}>
            {t("orders.updateStatus")}
          </Typography>
          <Grid container spacing={3}>
            {/* Status Selector */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">
                  {t("orders.newStatus")}
                </InputLabel>
                <Select
                  labelId="status-select-label"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  label={t("orders.newStatus")}
                >
                  <MenuItem value="PENDING">
                    {t("orders.statuses.pending")}
                  </MenuItem>
                  <MenuItem value="IN_PROGRESS">
                    {t("orders.statuses.in_progress")}
                  </MenuItem>
                  <MenuItem value="SHIPPED">
                    {t("orders.statuses.shipped")}
                  </MenuItem>
                  <MenuItem value="DELIVERED">
                    {t("orders.statuses.delivered")}
                  </MenuItem>
                  <MenuItem value="CANCELLED">
                    {t("orders.statuses.cancelled")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Estimated Delivery Date Picker */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  selected={estimatedDeliveryDate}
                  onChange={(date) => setEstimatedDeliveryDate(date)}
                  showTimeSelect
                  dateFormat="Pp"
                  customInput={
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={t("orders.estimatedDeliveryDate")}
                    />
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleStatusUpdate}
              >
                {t("orders.updateStatus")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Snackbar for status updates */}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrderDetailsPanel;
