import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  IconButton,
  MenuItem,
  Tooltip,
  Card,
  CardContent,
  Tab,
  Tabs,
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Avatar,
  InputAdornment,
  Paper,
  ClickAwayListener,
  Popper,
  Grow,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ProductForm = ({
  initialProduct,
  onSubmit,
  categories,
  relatedProductOptions,
  onRelatedProductSearchChange,
  onCancel,
}) => {
  const getDefaultProduct = useCallback(
    () => ({
      name: "",
      description: "",
      price: "",
      discounted_price: "",
      cost_per_item: "",
      sku: "",
      barcode: "",
      track_quantity: true,
      continue_selling_when_out_of_stock: false,
      quantity: 0,
      physical_product: true,
      weight: "",
      country_of_origin: "",
      hs_code: "",
      meta_title: "",
      meta_description: "",
      url_slug: "",
      active: true,
      category: "",
      product_type: "",
      vendor: "",
      collections: [],
      variants: [],
      options: [],
      image_urls: [],
      related_product_ids: [],
      offers: [],
    }),
    []
  );

  const { t } = useTranslation();
  const [product, setProduct] = useState(initialProduct || getDefaultProduct());
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [relatedProductInput, setRelatedProductInput] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    updateVariants(product.options);
  }, [product.options]);

  const renderTip = useCallback(
    (tipKey) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 193, 7, 0.1)',
          borderRadius: '8px',
          padding: '12px 16px',
          marginTop: '8px',
          marginBottom: '8px',
          border: '1px solid rgba(255, 193, 7, 0.5)',
        }}
      >
        <WarningAmberIcon sx={{ color: 'warning.main', marginRight: '12px' }} />
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t(tipKey)}
        </Typography>
      </Box>
    ),
    [t]
  );

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: name === "related_product_ids" ? [...value] : value,
    }));
  }, []);

  const handleDescriptionChange = useCallback((content) => {
    setProduct((prevProduct) => ({ ...prevProduct, description: content }));
  }, []);

  const addOption = useCallback(() => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      options: [...prevProduct.options, { name: "", values: [] }],
    }));
  }, []);

  const removeOption = useCallback((index) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      options: prevProduct.options.filter((_, i) => i !== index),
    }));
  }, []);

  const updateVariants = useCallback((options) => {
    const newVariants = generateVariants(options);
    setProduct((prevProduct) => ({ ...prevProduct, variants: newVariants }));
  }, []);

  const generateVariants = useCallback((options) => {
    if (options.length === 0) return [];

    const variants = [{}];
    options.forEach((option, index) => {
      const newVariants = [];
      variants.forEach((variant) => {
        option.values.forEach((value) => {
          newVariants.push({ ...variant, [`option${index + 1}`]: value });
        });
      });
      variants.splice(0, variants.length, ...newVariants);
    });

    return variants.map((variant) => ({
      ...variant,
      price: "",
      discounted_price: "",
      sku: "",
      barcode: "",
      quantity: "",
      weight: "",
      cost_per_item: "",
    }));
  }, []);

  const handleOptionChange = useCallback(
    (index, field, value) => {
      setProduct((prevProduct) => {
        const newOptions = [...prevProduct.options];
        newOptions[index][field] = value;
        const newVariants = generateVariants(newOptions);

        // Preserve existing variant data
        const updatedVariants = newVariants.map((newVariant) => {
          const existingVariant = prevProduct.variants.find((v) =>
            Object.keys(newVariant)
              .filter((key) => key.startsWith("option"))
              .every((key) => v[key] === newVariant[key])
          );
          return existingVariant
            ? { ...newVariant, ...existingVariant }
            : newVariant;
        });

        return {
          ...prevProduct,
          options: newOptions,
          variants: updatedVariants,
        };
      });
    },
    [generateVariants]
  );

  const handleVariantChange = useCallback((index, field, value) => {
    setProduct((prevProduct) => {
      const newVariants = [...prevProduct.variants];
      newVariants[index] = { ...newVariants[index], [field]: value };
      return { ...prevProduct, variants: newVariants };
    });
  }, []);

  const handleImageUpload = useCallback((event) => {
    const files = Array.from(event.target.files);
    const newImageFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setNewImages((prevImages) => [...prevImages, ...newImageFiles]);
    setProduct((prevProduct) => ({
      ...prevProduct,
      image_urls: [
        ...prevProduct.image_urls,
        ...newImageFiles.map((img) => img.preview),
      ],
    }));
  }, []);

  const removeImage = useCallback((index) => {
    setProduct((prevProduct) => {
      const updatedImageUrls = [...prevProduct.image_urls];
      const removedImage = updatedImageUrls.splice(index, 1)[0];

      if (
        typeof removedImage === "string" &&
        !removedImage.startsWith("blob:")
      ) {
        setRemovedImages((prev) => [...prev, removedImage]);
      } else {
        setNewImages((prev) => prev.filter((_, i) => i !== index));
      }

      return { ...prevProduct, image_urls: updatedImageUrls };
    });
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    setProduct((prevProduct) => {
      const newImages = Array.from(prevProduct.image_urls);
      const [reorderedItem] = newImages.splice(result.source.index, 1);
      newImages.splice(result.destination.index, 0, reorderedItem);
      return { ...prevProduct, image_urls: newImages };
    });
  }, []);

  const validateProduct = useCallback(
    (product) => {
      const errors = {};
      if (!product.name) {
        errors.name = t("products.errors.nameRequired");
        setActiveTab(0);
      }
      if (!product.price) {
        errors.price = t("products.errors.priceRequired");
        setActiveTab(2);
      }
      if (!product.category) {
        errors.category = t("products.errors.categoryRequired");
        setActiveTab(9);
      }
      if (!product.url_slug) {
        errors.url_slug = t("products.errors.urlSlugRequired");
        setActiveTab(8);
      }
      return errors;
    },
    [t]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateProduct(product);
      if (Object.keys(validationErrors).length === 0) {
        const productData = {
          ...product,
          price: Number(product.price),
          discounted_price: product.discounted_price
            ? Number(product.discounted_price)
            : null,
          variations: product.variants.map((variant) => ({
            price: Number(variant.price) > 0 ? Number(variant.price) : Number(product.price),
            discounted_price: variant.discounted_price
              ? Number(variant.discounted_price)
              : null,
            image_url: variant.image_url,
            sku: variant.sku,
            barcode: variant.barcode,
            quantity: variant.quantity ? parseInt(variant.quantity, 10) : 0,
            weight: variant.weight ? Number(variant.weight) : null,
            cost_per_item: variant.cost_per_item
              ? Number(variant.cost_per_item)
              : null,
            options: Object.entries(variant)
              .filter(([key]) => key.startsWith("option"))
              .map(([key, value]) => ({
                option_name: product.options[parseInt(key.slice(6)) - 1].name,
                option_value: value,
              })),
          })),
          related_products: product.related_product_ids,
          offers: product.offers.map((offer) => ({
            quantity: offer.quantity ? parseInt(offer.quantity, 10) : 0,
            price: offer.price ? Number(offer.price) : Number(product.price),
            free_shipping: offer.free_shipping || false,
          })),
          quantity: product.quantity ? parseInt(product.quantity, 10) : 0,
          weight: product.weight ? Number(product.weight) : null,
          cost_per_item: product.cost_per_item
            ? Number(product.cost_per_item)
            : null,
          options: product.options.map((option) => ({
            name: option.name,
            values: option.values,
          })),
          remove_images: removedImages,
          new_images: newImages.map((img) => img.file),
        };

        try {
          await onSubmit(productData);
        } catch (error) {
          setErrors({ submit: "Failed to submit product. Please try again." });
        }
      } else {
        setErrors(validationErrors);
      }
    },
    [product, onSubmit, validateProduct, removedImages, newImages]
  );

  const handleRelatedProductInputChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setRelatedProductInput(newValue);
      onRelatedProductSearchChange(newValue);
    },
    [onRelatedProductSearchChange]
  );

  const clearRelatedProductSearch = useCallback(() => {
    setRelatedProductInput("");
    onRelatedProductSearchChange("");
  }, [onRelatedProductSearchChange, relatedProductOptions]);

  const handleSearchFocus = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(true);
  };

  const handleClickAway = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  const handleRelatedProductChange = useCallback((selectedProductId) => {
    setProduct((prevProduct) => {
      const updatedRelatedProductIds = prevProduct.related_product_ids.includes(
        selectedProductId
      )
        ? prevProduct.related_product_ids.filter(
          (id) => id !== selectedProductId
        )
        : [...prevProduct.related_product_ids, selectedProductId];

      return {
        ...prevProduct,
        related_product_ids: updatedRelatedProductIds,
      };
    });
  }, []);

  const removeRelatedProduct = useCallback((productId) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      related_product_ids: prevProduct.related_product_ids.filter(
        (id) => id !== productId
      ),
    }));
  }, []);

  const handleOfferChange = useCallback((index, field, value) => {
    setProduct((prevProduct) => {
      const newOffers = [...prevProduct.offers];
      newOffers[index] = { ...newOffers[index], [field]: value };
      return { ...prevProduct, offers: newOffers };
    });
  }, []);

  const addOffer = useCallback(() => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      offers: [
        ...prevProduct.offers,
        { quantity: 1, price: "", free_shipping: false },
      ],
    }));
  }, []);

  const removeOffer = useCallback((index) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      offers: prevProduct.offers.filter((_, i) => i !== index),
    }));
  }, []);

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code-block"],
      ["link", "image"],
      ["clean"]
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "blockquote",
    "code-block",
    "link",
    "image"
  ];

  const quillStyles = {
    editor: {
      minHeight: "300px",
      background: "#ffffff",
    },
    toolbar: {
      background: "#f1f3f4",
      border: "1px solid #ccc",
      borderRadius: "4px 4px 0 0",
    },
    content: {
      border: "1px solid #ccc",
      borderTop: "none",
      borderRadius: "0 0 4px 4px",
      color: "#333",
    },
  };

  const tabs = useMemo(
    () => [
      {
        label: t("products.basicInfo"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {renderTip("products.tips.name")}
                  <Box display="flex" alignItems="center">
                    <TextField
                      required
                      fullWidth
                      name="name"
                      label={t("products.name")}
                      value={product.name}
                      onChange={handleInputChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="flex-start" sx={{ width: '100%' }}>
                    <Box flexGrow={1} sx={{ width: '100%' }}>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("products.description")}
                      </Typography>
                      {renderTip("products.tips.description")}
                      <Box sx={{
                        '.ql-toolbar': quillStyles.toolbar,
                        '.ql-container': quillStyles.content,
                        '.ql-editor': quillStyles.editor,
                      }}>
                        <ReactQuill
                          value={product.description}
                          onChange={handleDescriptionChange}
                          modules={quillModules}
                          formats={quillFormats}
                          theme="snow"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.media"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  {t("products.uploadImages")}
                </Button>
              </label>
              <Box mt={2}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="images" direction="horizontal">
                    {(provided) => (
                      <Grid
                        container
                        spacing={2}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {product.image_urls?.map((image, index) => (
                          <Draggable
                            key={index}
                            draggableId={`image-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <Grid
                                item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box sx={{ position: "relative" }}>
                                  <img
                                    src={image}
                                    alt={`Product ${index + 1}`}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      objectFit: "cover",
                                    }}
                                  />
                                  <IconButton
                                    size="small"
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      backgroundColor: "rgba(255,255,255,0.7)",
                                    }}
                                    onClick={() => removeImage(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.pricing"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {renderTip("products.tips.price")}
                  <Box display="flex" alignItems="center">
                    <TextField
                      required
                      fullWidth
                      name="price"
                      label={t("products.price")}
                      type="number"
                      value={product.price}
                      onChange={handleInputChange}
                      error={!!errors.price}
                      helperText={errors.price}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderTip("products.tips.discountedPrice")}
                  <Box display="flex" alignItems="center">
                    <TextField
                      fullWidth
                      name="discounted_price"
                      label={t("products.discountedPrice")}
                      type="number"
                      value={product.discounted_price}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                {renderTip("products.tips.costPerItem")}
                  <TextField
                    fullWidth
                    name="cost_per_item"
                    label={t("products.costPerItem")}
                    type="number"
                    value={product.cost_per_item}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.inventory"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="sku"
                    label={t("products.sku")}
                    value={product.sku}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="barcode"
                    label={t("products.barcode")}
                    value={product.barcode}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.track_quantity}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            track_quantity: e.target.checked,
                          })
                        }
                        name="track_quantity"
                      />
                    }
                    label={t("products.trackQuantity")}
                  />
                </Grid>
                {product.track_quantity && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="quantity"
                      label={t("products.quantity")}
                      type="number"
                      value={product.quantity}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.continue_selling_when_out_of_stock}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            continue_selling_when_out_of_stock:
                              e.target.checked,
                          })
                        }
                        name="continue_selling_when_out_of_stock"
                      />
                    }
                    label={t("products.continueSelling")}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.offers"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h6" mr={2}>
                  {t("products.offers")}
                </Typography>
                {renderTip("products.tips.offers")}
              </Box>
              {product.offers.map((offer, index) => (
                <Box key={index} mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label={t("products.offerQuantity")}
                        type="number"
                        value={offer.quantity}
                        onChange={(e) =>
                          handleOfferChange(index, "quantity", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label={t("products.offerPrice")}
                        type="number"
                        value={offer.price}
                        onChange={(e) =>
                          handleOfferChange(index, "price", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={offer.free_shipping}
                            onChange={(e) =>
                              handleOfferChange(
                                index,
                                "free_shipping",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={t("products.freeShipping")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => removeOffer(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={addOffer}>
                {t("products.addAnotherOffer")}
              </Button>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.relatedProducts"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                    {renderTip("products.tips.crossSelling")}
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={t("products.searchRelatedProducts")}
                        value={relatedProductInput}
                        onChange={handleRelatedProductInputChange}
                        onFocus={handleSearchFocus}
                        inputRef={searchInputRef}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: relatedProductInput && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={clearRelatedProductSearch}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Popper
                        open={isDropdownOpen}
                        anchorEl={anchorEl}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-start"
                        style={{
                          width: anchorEl?.offsetWidth,
                          zIndex: 1300,
                        }}
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper elevation={8}>
                              <List
                                sx={{
                                  maxHeight: 300,
                                  overflowY: "auto",
                                  width: "100%",
                                }}
                              >
                                {relatedProductOptions.map((option) => (
                                  <ListItemButton
                                    key={option.id}
                                    dense
                                    onClick={() =>
                                      handleRelatedProductChange(option.id)
                                    }
                                  >
                                    <ListItemSecondaryAction>
                                      <Checkbox
                                        edge="end"
                                        checked={product.related_product_ids.includes(
                                          option.id
                                        )}
                                        tabIndex={-1}
                                        disableRipple
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() =>
                                          handleRelatedProductChange(option.id)
                                        }
                                      />
                                    </ListItemSecondaryAction>
                                    <Avatar
                                      alt={option.name}
                                      src={
                                        option.main_image ||
                                        "/api/placeholder/40/40"
                                      }
                                      sx={{ marginRight: 2 }}
                                    />
                                    <ListItemText primary={option.name} />
                                  </ListItemButton>
                                ))}
                              </List>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {product.related_product_ids.map((id) => {
                      const relatedProduct = relatedProductOptions.find(
                        (option) => option.id === id
                      );
                      return relatedProduct ? (
                        <Chip
                          key={id}
                          avatar={
                            <Avatar
                              alt={relatedProduct.name}
                              src={
                                relatedProduct.main_image ||
                                "/api/placeholder/40/40"
                              }
                            />
                          }
                          label={relatedProduct.name}
                          onDelete={() => removeRelatedProduct(id)}
                          deleteIcon={<CloseIcon />}
                        />
                      ) : null;
                    })}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.shipping"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.physical_product}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            physical_product: e.target.checked,
                          })
                        }
                        name="physical_product"
                      />
                    }
                    label={t("products.physicalProduct")}
                  />
                </Grid>
                {product.physical_product && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="weight"
                        label={t("products.weight")}
                        type="number"
                        value={product.weight}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="country_of_origin"
                        label={t("products.countryOfOrigin")}
                        value={product.country_of_origin}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="hs_code"
                        label={t("products.hsCode")}
                        value={product.hs_code}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.variants"),
        content: (
          <Card elevation={0}>
            <CardContent>
              {product.options?.map((option, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      {renderTip("products.tips.optionName")}
                      <TextField
                        fullWidth
                        value={option.name}
                        onChange={(e) =>
                          handleOptionChange(index, "name", e.target.value)
                        }
                        label={t("products.optionName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {renderTip("products.tips.optionValues")}
                      <TextField
                        fullWidth
                        value={option.values.join(", ")}
                        onChange={(e) =>
                          handleOptionChange(
                            index,
                            "values",
                            e.target.value.split(",").map((v) => v.trim())
                          )
                        }
                        label={t("products.optionValues")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton onClick={() => removeOption(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={addOption}>
                {t("products.addAnotherOption")}
              </Button>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" gutterBottom>
                {t("products.variantsList")}
              </Typography>
              {renderTip("products.tips.variants")}
              {product.variants?.map((variant, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {Object.entries(variant)
                        .filter(([key]) => key.startsWith("option"))
                        .map(([_, value]) => value)
                        .join(" / ")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="price"
                          label={t("products.price")}
                          type="number"
                          value={variant.price}
                          onChange={(e) =>
                            handleVariantChange(index, "price", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="discounted_price"
                          label={t("products.discountedPrice")}
                          type="number"
                          value={variant.discounted_price}
                          onChange={(e) =>
                            handleVariantChange(
                              index,
                              "discounted_price",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="cost_per_item"
                          label={t("products.costPerItem")}
                          type="number"
                          value={variant.cost_per_item}
                          onChange={(e) =>
                            handleVariantChange(
                              index,
                              "cost_per_item",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {renderTip("products.tips.sku")}
                        <TextField
                          fullWidth
                          name="sku"
                          label={t("products.sku")}
                          value={variant.sku}
                          onChange={(e) =>
                            handleVariantChange(index, "sku", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {renderTip("products.tips.barcode")}
                        <TextField
                          fullWidth
                          name="barcode"
                          label={t("products.barcode")}
                          value={variant.barcode}
                          onChange={(e) =>
                            handleVariantChange(
                              index,
                              "barcode",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="quantity"
                          label={t("products.quantity")}
                          type="number"
                          value={variant.quantity}
                          onChange={(e) =>
                            handleVariantChange(
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="weight"
                          label={t("products.weight")}
                          type="number"
                          value={variant.weight}
                          onChange={(e) =>
                            handleVariantChange(index, "weight", e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.seo"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {renderTip("products.tips.metaTitle")}
                  <TextField
                    fullWidth
                    name="meta_title"
                    label={t("products.metaTitle")}
                    value={product.meta_title}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {renderTip("products.tips.metaDescription")}
                  <TextField
                    fullWidth
                    name="meta_description"
                    label={t("products.metaDescription")}
                    multiline
                    rows={4}
                    value={product.meta_description}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {renderTip("products.tips.urlSlug")}
                  <TextField
                    required
                    fullWidth
                    name="url_slug"
                    label={t("products.urlSlug")}
                    value={product.url_slug}
                    onChange={handleInputChange}
                    error={!!errors.url_slug}
                    helperText={errors.url_slug}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.organization"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    select
                    name="category"
                    label={t("products.category")}
                    value={product.category}
                    onChange={handleInputChange}
                    error={!!errors.category}
                    helperText={errors.category}
                  >
                    {categories?.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="product_type"
                    label={t("products.productType")}
                    value={product.product_type}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="vendor"
                    label={t("products.vendor")}
                    value={product.vendor}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
      {
        label: t("products.status"),
        content: (
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    name="active"
                    label={t("products.status")}
                    value={product.active}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={true}>{t("products.active")}</MenuItem>
                    <MenuItem value={false}>{t("products.draft")}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ),
      },
    ],
    [
      t,
      product,
      handleInputChange,
      handleDescriptionChange,
      handleOfferChange,
      handleOptionChange,
      addOption,
      removeOption,
      handleVariantChange,
      handleImageUpload,
      removeImage,
      onDragEnd,
      handleRelatedProductChange,
      handleRelatedProductInputChange,
      clearRelatedProductSearch,
      handleSearchFocus,
      removeRelatedProduct,
    ]
  );

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 2 }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      {tabs[activeTab].content}
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onCancel} variant="outlined">
          {t("common.cancel")}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {initialProduct?.id ? t("common.update") : t("common.create")}
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(ProductForm);
